import {Swiper, SwiperSlide} from "swiper/react";

// Import CustomSwiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./CustomSwiper.css";

// import required modules
import {FreeMode, Pagination} from "swiper";
import ProductCard from "../ProductCard/ProductCard";
import {Ikategori} from "../../../models/models";
import {useNavigate} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import {useContext} from "react";
import {Context} from "../../../index";

export default function CustomSwiper({data, title, toSub, id, isAuth, isMainPage}: Ikategori) {
    let ListCards: any = [];
    const {store} = useContext(Context)
    const navigate = useNavigate()
    const widthWindow = window.innerWidth
    const maxCard = widthWindow > 450? 9 : 3
    
    if (data)
        if (data.length != 0)
            ListCards = data.map((card, index) => {
                if (isMainPage && index < maxCard) {
                    return (
                        <SwiperSlide style={{width: "min-content"}} key={index + card.productId}>
                            <ProductCard
                                isAuth={isAuth ? isAuth : false}
                                key={index + card.productId}
                                item={card}
                            />
                        </SwiperSlide>
                    )
                }
            })

    const goCategory = () => {
        navigate({
            pathname: SpaRoutes.CATALOG,
            search: `?tc=${id}`,
        });
    }

    const goSubCategory = () => {
        navigate({
            pathname: SpaRoutes.CATALOG,
            search: `?sc=${id}`,
        });
    }

    return (<>
            {data && data.length != 0 &&
                <>
                    <div className={"categories-div"} onClick={() => {
                        toSub ?
                            goSubCategory()
                            : goCategory()
                    }}>
                        <p className={"tag-name"}>{title}<img/></p>
                    </div>
                    <Swiper
                        key={id}
                        slidesPerView={"auto"}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="productSwiper"
                    >
                        {ListCards}
                        <SwiperSlide onClick={() => {
                            goCategory()
                        }}>
                            <div className={"swiperSeeMoreContainer"}>
                                <p className={"swiperSeeMoreContainer__text"}>Больше<br/> товаров в каталоге</p>
                                <div className={"swiperSeeMoreContainer__button"}>В каталог</div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </>
            }
        </>
    );
}