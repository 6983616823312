import React, {useContext, useEffect, useState} from 'react';
import styles from "./PricesTable.module.scss"
import CustomAntTable from "../../../../customUI/CustomAntTable/CustomAntTable";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import AdminService from "../../../../../services/AdminService";
import {AddEditPricePopup} from "./AddEditPricePopup";
import {Context} from "../../../../../index";

interface ITableData {
    id: string,
    count: number,
    price: number
}

const PricesTable = () => {
    const {store} = useContext(Context)

    const [tableData, setTableData] = useState<ITableData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [addEditPricePopup, setAddEditPricePopup] = useState<JSX.Element>(<></>);

    const [canSend, setCanSend] = useState(false)

    const getTableData = () => {
        setLoading(true);
        AdminService.getPricesTable()
            .then((r) => {
                setTableData(r.data.sort((a: any, b: any) => a.count - b.count))
            })
            .catch()
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getTableData()
    }, []);


    const columns = [
        {
            title: "№",
            dataIndex: "id",
            render: (_: any, record: any, index: number) => index + 1,
            width: 30
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            width: 150
        },
        {
            title: "Цена за ед.",
            dataIndex: "price",
            render: (price: number) => `${price.toFixed(2)} ₽`,
            width: 150
        },
        {
            title: "Цена за 1 д.",
            dataIndex: "pricePerDay",
            render: (_: any, record: ITableData) => `${(record.price * record.count).toFixed(2)} ₽`,
            width: 150
        },
        {
            title: "Цена за 30 д.",
            dataIndex: "pricePerMonth",
            render: (_: any, record: ITableData) => `${(record.price * record.count * 30).toFixed(2)} ₽`,
            width: 150
        },
        {
            title: "",
            dataIndex: "action",
            render: (_: any, record: any, index: number) => {
                return <div className={styles.tableButtons}>
                    <button
                        onClick={() => editPricePopup(index,record.count, record.price)}
                        className={styles.buttonEdit}
                    >
                        Изменить
                    </button>
                    <img
                        onClick={() => deletePrice(record.id)}
                        src={"/Pictures/Trash-black.svg"}
                        alt={"delete"}
                    />
                </div>
            },
            width: 50
        }
    ]

    const addPrice = (count: number, price: number) => {
        let mas = tableData
        mas.push({id: `new ${tableData.length + 1}`, count: count, price: price})
        mas.sort((a, b) => a.count - b.count)
        setTableData(mas)
        setCanSend(true)
    }
    const editPrice = (index: number, count: number, price: number) => {
        let mas = tableData
        mas[index] = {"id": mas[index].id, "count": count, "price": price}
        mas.sort((a, b) => a.count - b.count)
        setTableData(mas)
        setCanSend(true)
    }

    const addPricePopup = () => {
        setAddEditPricePopup(
            <AddEditPricePopup
                addData={addPrice}
                onClose={() => setAddEditPricePopup(<></>)}
            />
        )
    }

    const editPricePopup = (index: number, count: number, price: number) => {
        setAddEditPricePopup(
            <AddEditPricePopup
                editData={editPrice}
                index={index}
                quantity={count}
                price={price}
                onClose={() => setAddEditPricePopup(<></>)}
            />
        )
    }

    const deletePrice = (id: string) => {
        setTableData((prevState) => prevState.filter((e) => e.id !== id))
    }

    const savePricesTable = () => {
        let data = {
            CardPricingTable: tableData
        }

        setLoading(true)

        AdminService.updatePricesTable(data)
            .then(() => store.showDialog("Цены успешно обновлены"))
            .catch(() => store.showDialog("Не удалось обновить цены"))
            .finally(() => setLoading(false))
    }

    return (
        <div className={styles.container}>
            <CustomAntTable
                data={tableData}
                columns={columns}
                loading={loading}
                scroll={{x: "max-content", y: "400px"}}
            />

            <div className={styles.buttons}>
                <button className={styles.button} onClick={addPricePopup}>
                    Добавить
                </button>
                <button className={styles.button} disabled={!canSend} onClick={savePricesTable}>
                    Сохранить
                </button>
            </div>
            {addEditPricePopup && addEditPricePopup}
        </div>
    );
};

export default PricesTable;