import './Counter.css'
import {ICounterProps} from "../../../models/CounterProps/CounterProps";

const Counter = (props: ICounterProps) => {
    const handleDecrease = () => {
        if (props.count > 1) {
            props.onDecrease();
        }
    }

    const handleIncrease = () => {
        if (props.maxValue && props.count >= props.maxValue) {
            return;
        }
        props.onIncrease();
    };

    return(
        <div className={'add-item'} style={props.disabled ? {visibility: "hidden"} : {}}>
            <img
                onClick={handleDecrease}
                src={"/Pictures/minus.svg"}/>
            <p className={"add-item-p"}>{props.count}</p>
            <img
                onClick={handleIncrease}
                src={"/Pictures/plus.svg"}/>
        </div>
    )
}

export default Counter;
