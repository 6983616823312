import React, {ChangeEvent, useCallback, useContext, useEffect, useRef, useState} from "react";
import Boxitem from "../../../../customUI/multipleCheckboxes/boxitem";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import {ChangeSupplierDropdawn} from "./ChangeSupplierDropdawn";
import {Ru} from "../../../../../functions/functions";
import Favorite from "../../../../customUI/Favorite/Favorite";
import ChangeAmountBar from "./ChangeAmountBar";
import DeleteProductButton from "./DeleteProductButton";
import $api from "../../../../../api/axios";
import {Context} from "../../../../../index";
import {ICard, ICardObject} from "../../../../../models/product/productCard";
import {ISupplier, ISupplierCardObj} from "../../../../../models/supplier/supplier";
import {CustomIMG} from "../../../../customUI/CustomIMG/CustomIMG";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";

interface ICartItemProps {
    item: ICard;
    level1name: any;
    level1data: any;
    level2name: string;
    CheckLevel1: (event: ChangeEvent<HTMLInputElement>, level2name: string) => void;
    deleteCardhandler: (level2name: string, id: string) => Promise<any>;
    disabled: boolean;
    changeSupplier: (id: string, level2: string, supplier: ISupplier, product: ICard) => Promise<any>;
    favoriteHandler: (level2: any, level1: any) => void;
    changeAmount: (orderId: string, newAmount: number, level2: string, productId: string) => void;
    key: string
}

function SupplierCardProduct(props: ICartItemProps) {
    const {store} = useContext(Context);
    const [showdropdown, setshowdropdown] = useState(false)

    const handleClickShow = () => {
        setshowdropdown(!showdropdown)
    }

    const clickSupplier = useCallback((supplier: ISupplier) => {
        setshowdropdown(false);
        CabinetBuyerService.productInfoForBasket(props.item.orderId, supplier.productID)
            .then(async data => {
                if (data.status == 200) {
                    props.changeSupplier(props.item.productId, props.level2name, supplier, data.data)
                        .then(() => {
                            store.cartNumber()
                        })
                }
            })
    }, [])

    const handleCheckitem = (event: ChangeEvent<HTMLInputElement>) => {
        props.CheckLevel1(event, props.level2name)
    }

    const Dropdownlist: any = useCallback(() => {
        let arr = [];
        if (props.item.suppliers?.length != 0) {
            // arr.push(
            //     <span className="search-dropdown"><img src={"/Pictures/search.svg"}/> Поиск</span>
            // );
            for (let i = 0; i < props.item.suppliers.length; i++) {
                if (props.item.currentSupplierId == props.item.suppliers[i].supplierID)
                    continue;
                let str: string = '';
                if (i == props.item.suppliers?.length - 1) {
                    str = "last-element";
                } else {
                    str = "center-element"
                }
                arr.push(
                    <ChangeSupplierDropdawn
                        key={props.item.suppliers[i].supplierID}
                        handleClick={() => clickSupplier(props.item.suppliers[i])}
                        suppliername={props.item.suppliers[i].suppliername}
                        supplierlogo={props.item.suppliers[i].supplierlogo}
                        isdropdownitem={true}
                        style={str}
                    />
                )
            }
        }
        return arr;
    }, [props.item.suppliers])


    const MemorizedDropdownlist = React.memo(Dropdownlist)

    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => setshowdropdown(false));
    return (
        <div className={`${props.disabled ? ("waitinglist-shadow") : ("")}`} key={props.key}>
            <div className={"cart-card"}>
                <div className={"display-flex"}>
                    <div className={"checkbox-container"}
                         style={props.disabled ? {display: "block"} : {display: "block"}}>
                        <Boxitem
                            name={props.level1name}
                            checked={props.level1data}
                            handleClick={handleCheckitem}
                            disabled={props.disabled}
                        />
                    </div>
                    <div className={"card-item-container"}>
                        <CustomIMG className={"card-item-img"} src={props.item.image}/>
                        <div style={{width: "100%"}}>
                            <p className={"card-item-name"}>{props.item.name_of_product}</p>
                            <div>
                                <div style={{display: "flex"}}>
                                    <div id={"cart-shangesupplier-scroll"} ref={ref}
                                         className={`dropdown-div ${showdropdown && 'dropdown-shadow'}`}>
                                        {(props.item.suppliers?.length > 0) &&
                                            <ChangeSupplierDropdawn
                                                handleClick={() => handleClickShow()}
                                                suppliername={props.item.supply_company}
                                                supplierlogo={props.item.image_supply_company}
                                                isdropdownitem={false}
                                                style={"first-element"}
                                                showdropdown={showdropdown}
                                            />}
                                        {showdropdown && <MemorizedDropdownlist/>}

                                    </div>
                                    <Favorite
                                        favorite={props.item.onFavorite}
                                        id={props.item.productId}
                                        level2={props.level2name}
                                        favoriteHandler={props.favoriteHandler}
                                        style={"favorite-img-Cart"}
                                    />
                                </div>
                                <p className={"card-item-amount"}>
                                    {props.disabled ? ("Нет в наличии") : (`В наличии: ${props.item.amount} шт.`)}
                                </p>
                            </div>
                        </div>

                    </div>

                </div>
                <div className={"card-item-footer"}>
                    <div className={"card-item-price-bonuses"}>
                        <div className={"card-item-price"}>
                            <p className={`${props.item.discount ? "card-item-price1" : "card-item-price2"}`}>{Ru(props.item.price1)}</p>
                            {props.item.discount && <p className={"card-item-price2"}>{Ru(props.item.price2)}</p>}
                        </div>

                        {(props.item?.bonuses > 0 && !props.item.visibleMaskPrice) &&
                            <p className='card-item-bonuses'>
                                +{props.item.bonuses} Б
                            </p>
                        }
                    </div>
                    <ChangeAmountBar
                        orderId={props.item.orderId}
                        orderAmount={props.item.orderAmount}
                        level2={props.level2name}
                        productId={props.item.productId}
                        onChangeAmount={props.changeAmount}
                        disabled={props.disabled}
                        supplierAmount={props.item.amount}
                    />
                    <DeleteProductButton
                        orderId={props.item.orderId}
                        deleteCardhandler={props.deleteCardhandler}
                        level2name={props.level2name}
                        productId={props.item.productId}
                    />
                </div>
            </div>
        </div>
    )
}

export default SupplierCardProduct;