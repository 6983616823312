import {ICard} from "../../../../models/product/productCard";
import {ISupplier, ISupplierAdress, ISupplierCardObj, ISupplierObject} from "../../../../models/supplier/supplier";
import {getProperty} from "../../../../functions/functions";

//функция превращения item массива поставщиков в корзине в объект
export function arrayitemtoCurtobject(item:any) {
    item.suppliercards = item.suppliercards.reduce(function(target:any, key:ICard, index:string) {
        index = key.productId;
        target[index] = key;
        return target;
    }, {})
    return(item)
}

//функция превращения массива поставщиков в объект объектов
export function arraytoCurtObject(suppliers:ISupplier[]) {
    suppliers.map((item:any)=>{
        arrayitemtoCurtobject(item)
    });
    const initialState = suppliers.reduce(function (target:any, key:any){
        target[key.suppliername] = key;
        return target;
    }, {})
    return initialState;
}

//функция подсчета поставщиков в объекте корзины
export function CountCurtObj(CartObj:any) {
    let Size = 0;
    for (const key in CartObj)
        if (CartObj.hasOwnProperty(key))
            for (const l2key in CartObj[key].suppliercards)
                if (CartObj[key].suppliercards[l2key])
                    Size += 1;
    return Size;
}

//функция подсчета всех продуктов всех поставщиков в объекте корзины включая лист ожидания
export function CountSupplierObj(CartObj:{}) {
    let Size = 0;
    for (const key in CartObj) {
        if (CartObj.hasOwnProperty(key)){
            // @ts-ignore
            if(CartObj[key].checked)
                Size += 1;
        }
    }
    return Size;
}

//функция удаления карточки продукта из корзины
export async function deleteCard(level2: any, id: any, setstate: (prevstate: any) => void) {
    let empty = false;
    await setstate((prevState: ISupplier[]) => {
        const newState: ISupplier[] = {...prevState};
        delete newState[level2].suppliercards[id]
        if (Object.keys(newState[level2].suppliercards).length == 0)
            delete newState[level2];
        if (Object.keys(newState).length < 1)
            empty = true;
        return newState;
    })
    if (empty)
        setstate(null);
}
//функция подсчета суммы со скидкой и без скидки в корзине (если discount = false)
export const sumProductsInCart = (state:ISupplierObject, discount:boolean)=>{
    let rez=0
    for(const level2key in state){
        let supplier = getProperty(state, level2key)
        rez += sumProductsInSupplier(supplier, discount)
    }
    return rez;
}
//функция подсчета суммы со скидкой и без скидки в карточке поставщика (если discount = false)
export const sumProductsInSupplier = (supplier:ISupplierCardObj, discount:boolean)=> {
    let rez=0
        for(const level1key in supplier.suppliercards){
            let card = getProperty(supplier.suppliercards, level1key)
            let price = 0;
            if(discount&&card.discount)
                price = card.price2
            else
                price = card.price1
            if(card.checked){
                rez+= price * card.orderAmount
            }
        }
    return rez;
}

export const sumBonusesInSupplier = (supplier:ISupplierCardObj)=> {
    let rez=0
    for(const level1key in supplier.suppliercards){
        let card = getProperty(supplier.suppliercards, level1key)
        if(card.checked){
            rez+= card.bonuses * card.orderAmount
        }
    }
    return rez;
}
//функция подсчета суммы со скидкой и без скидки (если discount = false)
export const sumProducts = (state:ISupplierObject, discount:boolean)=>{
    let rez=0
    for(const level2key in state){
        let supplier = getProperty(state, level2key)
        for(const level1key in supplier.suppliercards){
            let card = getProperty(supplier.suppliercards, level1key)
            let price = 0;
            if(discount&&card.discount)
                price = card.price2
            else
                price = card.price1
            if(card.checked){
                rez+= price * card.orderAmount
            }
        }
    }
    return rez;
}

export const sumBonuses = (state:ISupplierObject)=>{
    let rez=0
    for(const level2key in state){
        let supplier = getProperty(state, level2key)
        for(const level1key in supplier.suppliercards){
            let card = getProperty(supplier.suppliercards, level1key)
            if(card.checked){
                rez+= card.bonuses * card.orderAmount
            }
        }
    }
    return rez;
}
//функция поиска выбранных товаров в корзине и формирование листа их id
export const findCheckedCards = (state:any)=>{
    let idList = [];
    for(const level2key in state) {
        for (const level1key in state[level2key].suppliercards)
            if (state[level2key].suppliercards[level1key].checked &&
                state[level2key].suppliercards[level1key].amount != 0)
                idList.push(state[level2key].suppliercards[level1key].orderId)
    }
    return idList;
}
export const deliveryDateTransform = (date:Date)=>{
    return date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate()+"T"
}