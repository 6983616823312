import React, {useContext, useEffect, useState} from 'react';
import CustomInput from "../../../../customUI/CustomInput/CustomInput";
import AdminService from "../../../../../services/AdminService";
import {Context} from "../../../../../index";

interface ModalProps {
    onClose: () => void;
    addData?: (count: number, price: number) => void;
    editData?: (id: number, count: number, price: number) => void;
    quantity?: number;
    price?: number;
    index?: number;
}

export function AddEditPricePopup(props: ModalProps) {
    //const [date, setDate] = useState("")
    const {store} = useContext(Context)
    const [quantity, setQuantity] = useState<number>(props.quantity? props.quantity : 0)
    const [price, setPrice] = useState<number>(props.price? props.price : 0)

    const [canSend, setCanSend] = useState(false)

    const update = () => {
        if(canSend){
            props.onClose()
            if(props.editData && props.index)
                props.editData(props.index, Number(quantity), Number(price.toFixed(2)))
            else if(props.addData)
                props.addData(Number(quantity), Number(price.toFixed(2)))
        }
    }

    useEffect(() => {
        if(quantity && price)
            setCanSend(true)
        else
            setCanSend(false)
    }, [quantity, price])

    return (
        <>
            <div className={"addPrice-popup-close-wrapper"} onClick={props.onClose}/>
            <div className={"addPrice-popup-wrapper"}>
                <div className={"addPrice-popup-container"}>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>Количество</div>
                        <CustomInput
                            name={"priceCardAmount"}
                            type={"number"}
                            placeHolder={"Количество"}
                            value={quantity}
                            onChange={
                                (event) => setQuantity(event.target.value)
                            }
                            inputSize={"large"}
                        />
                    </div>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>Цена за 1 карточку</div>
                        <CustomInput
                            name={"priceCardPrice"}
                            type={"number"}
                            placeHolder={"Цена"}
                            value={price.toFixed(2)}
                            onChange={
                                (event) => setPrice(Number(event.target.value))
                            }
                            inputSize={"large"}
                            step={"0.01"}
                        />
                    </div>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>Цена за 1 день</div>
                        <CustomInput
                            name={"priceCardPrice"}
                            type={"number"}
                            placeHolder={"Цена"}
                            value={(price * (quantity ?? 0)).toFixed(2)}
                            onChange={
                                (event) => setPrice(Number((event.target.value) / quantity))
                            }
                            disabled={!(quantity > 0)}
                            inputSize={"large"}
                        />
                    </div>

                    <div className={"admin-mainSetting-input"}>
                        <div className={"admin-mainSetting-hint-text"}>Цена за 30 дней</div>
                        <CustomInput
                            name={"priceCardPrice"}
                            type={"number"}
                            placeHolder={"Цена"}
                            value={(price * (quantity ?? 0) * 30).toFixed(2)}
                            onChange={
                                (event) => setPrice(Number(event.target.value) / quantity / 30)
                            }
                            disabled={!(quantity > 0)}
                            inputSize={"large"}
                        />
                    </div>

                    <button className={`admin-mainSetting-button ${!canSend && "admin-mainSetting-button-disabled"}`}
                            onClick={update}>
                        {props.editData && props.index ? "Сохранить" : "Добавить"}
                    </button>
                </div>
            </div>
        </>
    )
}

