import {useLocation, useNavigate, useNavigation, useParams} from "react-router-dom";
import "./MyOrderPayment.css";
import React, {useCallback, useContext, useEffect, useState} from "react";
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Checkbox01 from "../../../../customUI/checkbox01/checkbox01";
import FunctionButton from "../../../../customUI/FunctionButton/FunctionButton";
import CabinetBuyerService from "../../../../../services/CabinetBuyerService";
import {MyOrderPaymentModal} from "./MyOrderPaymentModal/MyOrderPaymentModal";
import {ModalContext} from "../../../../../context/ModalContext";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../../customUI/Loader/Loader";
import {Context} from "../../../../../index";


export default function MyOrderPayment() {

    const {store} = useContext(Context);

    const [openModal, setOpenModal] = useState(false);
    const [openModalFail, setOpenModalFail] = useState(false);
    const [loading, setLoading] = useState(true);
    const [paymentStatus, setPaymentStatus] = useState<number>(0);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const {modal, open, close} = useContext(ModalContext);
    const navigate = useNavigate();
    const params = useParams();
    const InvoiceId = params.id || "";
    const price = params.price || "0";
    const chosenOption = Number(params.paymentOption) || 0;

    useEffect(() => {
        setPaymentStatus(chosenOption);
    }, [])

    const postKindOfPayment = async () => {
        setLoading(false);
        if (InvoiceId)
            return CabinetBuyerService.setPaymentStatus(InvoiceId, paymentStatus)
                .then((response) => {
                    if (response.status === 200) {
                        if(paymentStatus == 3) {
                            store.setUser({
                                ...store.user,
                                bonuses: store.user.bonuses - Number.parseFloat(price.replace("₽", "")),
                                bonusesHold: store.user.bonusesHold + Number.parseFloat(price.replace("₽", ""))
                            })
                        }
                        setLoading(true)
                        setOpenModal(true)
                    } else {
                        setLoading(true);
                        setOpenModalFail(true);
                    }
                });
        else return store.showDialog("Cannot find InvoiceId").then(() => {
        });
    };

    const PaymentOptions: { name: string; id: number; }[] = [
        {name: "Наличные", id: 1},
        {name: "Банковская карта", id: 2},
        {name: "Бонусы", id: 3},
        {name: "Со счета", id: 4},
        {name: "QR-код", id: 5},
        {name: "По терминалу", id: 6}
    ];

    function ChangeHandler(paymentID: number) {
        if(params.isPayed != "1"){
            if(paymentID != 3 ){
                setPaymentStatus(paymentID);
            }
            else if(store.user.bonuses - Number.parseFloat(price.replace("₽", "")) > 0){
                setPaymentStatus(paymentID);
            }
            else if(store.user.bonuses - Number.parseFloat(price.replace("₽", "")) < 0){
                store.showDialog("Недостаточно бонусов")
            }
        }
    }

    const CloseModalHandler = () => {
        setOpenModal(false);
        setOpenModalFail(false);
        close();
        navigate(-1);
    }

    return (
        <>
            <CustomNavigateHeader
                title={`Заказ №${InvoiceId}`}
                trashClickHandler={() => null}
                withTrash={false}
            />

            <span id={"myorder-payment-upper-title"}>Оплата на платформе</span>

            <div className={"myorder-payment-userBonuses"}>
                На вашем счету:
                <span className={"myorder-payment-userBonuses-value"}>{store.user.bonuses}</span>
                Б
            </div>

            {store.user.bonusesHold > 0 &&
                <div className={"myorder-payment-userBonusesHold"}>
                    На удержании: {store.user.bonusesHold} Б
                </div>
            }

            <div id="myorder-payment-status-list">
                <div key={PaymentOptions[2].name} className={"payment-option"}>
                    <Checkbox01 checked={paymentStatus == PaymentOptions[2].id} changeHandler={() => ChangeHandler(PaymentOptions[2].id)}/>
                    <span className={"payment-option-description"}>{PaymentOptions[2].name}</span>
                    <span className={"payment-option-price"}>{price.replace("₽", "Б")} </span>
                </div>
            </div>

            <span id={"myorder-payment-upper-title"}>Оплата лично поставщику</span>

            <div id="myorder-payment-status-list">
                <div key={PaymentOptions[0].name} className={"payment-option"}>
                    <Checkbox01 checked={paymentStatus == PaymentOptions[0].id} changeHandler={() => ChangeHandler(PaymentOptions[0].id)}/>
                    <span className={"payment-option-description"}>{PaymentOptions[0].name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
                <div key={PaymentOptions[1].name} className={"payment-option"}>
                    <Checkbox01 checked={paymentStatus == PaymentOptions[1].id} changeHandler={() => ChangeHandler(PaymentOptions[1].id)}/>
                    <span className={"payment-option-description"}>{PaymentOptions[1].name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
                <div key={PaymentOptions[3].name} className={"payment-option"}>
                    <Checkbox01 checked={paymentStatus == PaymentOptions[3].id} changeHandler={() => ChangeHandler(PaymentOptions[3].id)}/>
                    <span className={"payment-option-description"}>{PaymentOptions[3].name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
                <div key={PaymentOptions[4].name} className={"payment-option"}>
                    <Checkbox01 checked={paymentStatus == PaymentOptions[4].id} changeHandler={() => ChangeHandler(PaymentOptions[4].id)}/>
                    <span className={"payment-option-description"}>{PaymentOptions[4].name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
                <div key={PaymentOptions[5].name} className={"payment-option"}>
                    <Checkbox01 checked={paymentStatus == PaymentOptions[5].id} changeHandler={() => ChangeHandler(PaymentOptions[5].id)}/>
                    <span className={"payment-option-description"}>{PaymentOptions[5].name}</span>
                    <span className={"payment-option-price"}>{price}</span>
                </div>
            </div>

            <div id="myorder-payment-confirm">
                <FunctionButton text={"Подтвердить"} onClickHandler={postKindOfPayment}
                                style={{fontSize: 13, fontWeight: 700, lineHeight: "16px", cursor: "pointer"}}/>
            </div>
            {openModal && <MyOrderPaymentModal
                title={"Выбор способа оплаты"}
                text={"Выбор произведен успешно!"}
                onClose={CloseModalHandler}>
            </MyOrderPaymentModal>}
            {openModalFail && <MyOrderPaymentModal
                title={"Выбор способа оплаты"}
                text={"Произошла ошибка! В данный момент невозможно выбрать способ оплаты для этого заказа"}
                onClose={CloseModalHandler}>
            </MyOrderPaymentModal>}
        </>
    )
}