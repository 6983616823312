import {Iburgermenu} from "../../models/models";
import {SpaRoutes} from "../../Routes/spaRoutes";

export const burger_menu:Iburgermenu[]=
    [
        {
            name: "Главная",
            link: '/',
        },
        {
            name: "Каталог",
            link: SpaRoutes.CATALOG,
        },
        {
            name: "Учётные данные",
            link: SpaRoutes.PROFILE,
        },
        {
            name: "Мои бонусы",
            link: SpaRoutes.Customer.Bonuses,
        },
        {
            name: "Поставщики",
            link: SpaRoutes.SUPPLIER_PAGE,
        },
        {
            name: "Мои заказы",
            link: SpaRoutes.MY_ORDERS_ACTIVE,
        },
        {
            name: "Завершенные заказы",
            link: SpaRoutes.MY_ORDERS_CLOSED,
        },
        {
            name: "Избранное",
            link: SpaRoutes.FAVORITES,
        },
        {
            name: "История просмотров",
            link: SpaRoutes.HISTORYVIEWED,
        },
        {
            name: "Чаты",
            link: SpaRoutes.CUSTOMER_CHAT_LIST,
        },
        {
            name: "Обучение",
            link: SpaRoutes.TRAINING,
        },
        {
            name: "О ПроКрасивое",
            link: SpaRoutes.ABOUT_US,
        },
        {
            name: "Выход",
            link: SpaRoutes.LOGOUT,
        },
        {
            name: "Войти",
            link: SpaRoutes.LOGIN,
        }


]