import React, {useContext, useEffect, useState} from 'react';
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import {Checkbox} from "antd";

interface ModalProps {
    id: string;
    officialName: string;
    name: string;
    onClose: () => void;
    updateData: () => void;
}

export function AddBonusesPopup(props: ModalProps) {
    //const [date, setDate] = useState("")
    const {store} = useContext(Context)
    const [bonuses, setBonuses] = useState(0)
    const [isFreeBonuses, setIsFreeBonuses] = useState(false)
    const [description, setDescription] = useState("")
    const [canSend, setCanSend] = useState(false)

    const AddBonuses = () => {
        if(canSend){
            AdminService.postAddBonusPoints(props.id, Number(bonuses), description, isFreeBonuses)
                .then(() => alert("Бонусы отправлены"))
                .catch(()=> store.ErrorON())
                .finally(() => {
                    props.onClose()
                    props.updateData()
                })
        }
    }

    useEffect(() => {
        if(Number(bonuses) || Number(bonuses) != 0)
            setCanSend(true)
        else
            setCanSend(false)
    }, [bonuses])

    return (
        <>
            <div className={"addBonuses-popup-close-wrapper"} onClick={props.onClose}/>
            <div className={"addBonuses-popup-wrapper"}>
                <div className={"addBonuses-popup-container"}>
                    <div className={"addBonuses-popup-navigate-title-div"}>
                        <img id={"arrow-img"} onClick={() => props.onClose()} src={"/Pictures/strelochka.svg"}/>
                        <p className={"cart-title"}>Пополнить баллы</p>
                    </div>

                    <div className={"addBonuses-popup-stroke"}>

                        <div className={"addBonuses-popup-name"}>
                            <div className={"addBonuses-popup-hint-text"}>Имя</div>
                            <CustomInput
                                disabled={true}
                                placeHolder={"Имя"}
                                value={props.officialName}
                                onChange={() => undefined}
                                inputSize={"small"}
                            />
                        </div>
                    </div>

                    <div className={"addBonuses-popup-stroke"}>
                        <div className={"addBonuses-popup-fio"}>
                            <div className={"addBonuses-popup-hint-text"}>ФИО</div>
                            <CustomInput
                                disabled={true}
                                placeHolder={"ФИО"}
                                value={props.name}
                                onChange={() => undefined}
                                inputSize={"small"}
                            />
                        </div>
                    </div>

                    <div className={"addBonuses-popup-stroke-bonuses"}>
                        <div className={"addBonuses-popup-bonuses"}>
                            <div className={"addBonuses-popup-hint-text"}>Количество баллов</div>
                            <CustomInput
                                icon={false}
                                type={"number"}
                                disabled={false}
                                placeHolder={"Количество баллов"}
                                value={bonuses}
                                onChange={(e) => setBonuses(e.target.value)}
                                inputSize={"small"}
                            />
                        </div>
                        <div className={"addBonuses-popup-freeBonuses"}>
                            <Checkbox
                                className={"addBonuses-popup-freeBonuses-checkBox"}
                                checked={isFreeBonuses}
                                onChange={() => setIsFreeBonuses(!isFreeBonuses)}
                            />
                            Бесплатные бонусы
                        </div>
                    </div>

                    <div className={"addBonuses-popup-stroke"}>
                        <div className={"addBonuses-popup-hint-text"}>Причина перевода</div>
                        <textarea className={"addBonuses-popup-description-input"}
                                  disabled={isFreeBonuses}
                                  placeholder={"Причина перевода"}
                                  value={description}
                                  onChange={(event) => setDescription(event.target.value)}
                        />
                    </div>

                    <div className={"addBonuses-popup-apply-container"}>
                        <button
                            className={`addBonuses-popup-apply ${!canSend&&"addBonuses-popup-apply-disabled"}`}
                            onClick={AddBonuses}
                            disabled={!canSend}
                        >
                            Отправить
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

