import {Link} from "react-router-dom";
import {burger_menu} from "../../../../../data/UserBurgerMenu/burger_menu";
import React, {useContext} from "react";
import {Context} from "../../../../../index";

interface Interface {
    link: string;
    handler: () => void;
    title: string;
}

export const BurgerItem = ({link, handler, title}: Interface) => {

    const {store} = useContext(Context)


    return (
        <li>
            <Link
                className="a-link"
                to={link}
                onClick={handler}
            >
                {title}

                {title === "Мои бонусы" && store.user && store.user.bonuses > 0 &&
                    <b style={{color: "#01aba2", marginLeft: "5px"}}>({store.user.bonuses} Б)</b>
                }
            </Link>
        </li>
    )
}