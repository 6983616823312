import SupplierCardProduct from "../SupplierCardProduct/SupplierCardProduct";
import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import Boxitem from "../../../../customUI/multipleCheckboxes/boxitem";
import {CountSupplierObj, sumBonusesInSupplier, sumProductsInSupplier} from "./../functions";
import './SupplierCard.css'
import {ICard} from "../../../../../models/product/productCard";
import {ISupplier, ISupplierCardObj} from "../../../../../models/supplier/supplier";
import {Ru} from "../../../../../functions/functions";
import {Context} from "../../../../../index";
import ProductTermsPopup from "../../../../PublicPages/ProductPage/TermsPopup/ProductTermsPopup";

interface IsuppleritemProps {
    data: ISupplierCardObj;
    CheckLevel1: (event: ChangeEvent<HTMLInputElement>, level2name: string) => void
    CheckLevel2: (event: ChangeEvent<HTMLInputElement>) => void;
    deleteCardhandler: (level2name: string, id: string) => Promise<any>;
    changeSupplier: (id: string, level2: string, supplier: ISupplier, product: ICard) => Promise<any>;
    isWaitingList: boolean;
    arrowhandler: (level2: any) => void;
    arrow: boolean;
    favoriteHandler: (level2: any, level1: any) => void;
    changeAmount: (orderId: string, newAmount: number, level2: string, productId: string) => void;
    className?: string
    key?: string
}

function SupplierCard(props: IsuppleritemProps) {
    const {store} = useContext(Context)

    const [termsPopupElem, setTermsPopupElem] = useState<JSX.Element | null>(null)

    let sumOfNumbers: number = sumProductsInSupplier(props.data, true);
    let sumOfNumbersOld: number = sumProductsInSupplier(props.data, false);

    let sumBonuses = sumBonusesInSupplier(props.data)

    //const [sumBonuses, setSumBonuses] = useState(0);

    const handleCheckLevel2 = (event: ChangeEvent<HTMLInputElement>) => {
        props.CheckLevel2(event)
    }

    const Listlevel1: any = () => {
        let list = [];
        for (var key in props.data.suppliercards) {
            list.push(
                <SupplierCardProduct
                    key={props.data.suppliercards[key].productId}
                    item={props.data.suppliercards[key]}
                    level1name={props.data.suppliercards[key].productId.toString()}
                    level1data={props.data.suppliercards[key].checked}
                    level2name={props.data.suppliername}
                    disabled={props.isWaitingList}
                    CheckLevel1={props.CheckLevel1}
                    deleteCardhandler={props.deleteCardhandler}
                    changeSupplier={props.changeSupplier}
                    favoriteHandler={props.favoriteHandler}
                    changeAmount={props.changeAmount}
                />
            );
        }
        return (list)
    }
    const MemorizedListLevel1 = React.memo(Listlevel1)
    let Size = CountSupplierObj(props.data.suppliercards);

    return (
        <div className={"supplier-card-div"} key={props.key}>
            <div
                className={props.className ? `supplier-title-div ${props.className}` : "supplier-title-div"}
                onClick={() => props.arrowhandler(props.data.suppliername)}>
                <div className={"logo-text-amount"}>
                    {props.data.supplierlogo &&
                        <img className={"logo"} src={store.imgServer + props.data.supplierlogo}/>}
                    <p id="logo-text-amount-p">{props.className ?
                        `${props.data.suppliername} (${Size})` :
                        `${props.data.suppliername} - ${Size} позиции(й)`}</p>
                </div>
                <div className={"price-arrow"}>
                    {sumOfNumbers != 0 && <p>{Ru(sumOfNumbers)}</p>}
                    <img
                        style={props.arrow ? {transform: "scale(-1, 1)"} : {transform: "scale(1, -1)"}}
                        className={"arrow"} src={props.className ? "/Pictures/Vector 42.svg" : "/Pictures/arrow.svg"}/>
                </div>
            </div>
            <div id={"products-container"} style={props.arrow ? {display: "block"} : {display: "none"}}>
                {!props.isWaitingList && props.data?.terms &&
                    <div className={"supplier-terms"}>
                        {props.data?.terms && props.data.terms.visible && (
                            <div className='product-detailed-terms' style={{margin: "10px 0"}}>
                                <button onClick={
                                    () => setTermsPopupElem(
                                        <ProductTermsPopup terms={props.data?.terms}
                                                           onClose={() => setTermsPopupElem(null)}/>
                                    )}
                                >
                                    Условия доставки
                                </button>
                            </div>
                        )}
                        {props.data?.terms?.pickUpBonuses > 0 &&
                        <span style={{
                            marginBottom: "10px",
                            fontSize: "14px",
                            fontWeight: 500
                        }}>
                                При самовывозе <b
                            style={{color: "#01aba2",}}>+{props.data?.terms?.pickUpBonuses} бонусов</b>
                            {props.data?.terms?.pickUpBonusesStartSum > 0 ? ` от суммы заказа в ${props.data?.terms?.pickUpBonusesStartSum} ₽` : ""}
                            </span>
                        }
                    </div>
                }
                <div style={props.isWaitingList ? {display: "none"} : {display: "block"}}>
                    <Boxitem
                        name={props.data.suppliername}
                        title={"Все позиции"}
                        checked={props.data.checked}
                        handleClick={handleCheckLevel2}
                        disabled={props.isWaitingList}
                    />
                </div>
                <MemorizedListLevel1/>

                {!props.isWaitingList &&
                    <div>
                        {sumOfNumbers != sumOfNumbersOld &&
                            <p className={"all-price-old"}>{Ru(sumOfNumbersOld)}</p>
                        }
                        <div className={"all-price-bonuses-container"}>
                            <p className={"all-price-text"}>Итого:</p>
                            <p className={"all-price-bonuses"}>
                                {Ru(sumOfNumbers)}
                                {(sumBonuses > 0 && !props.data.visibleMaskPrice) &&
                                    <p className='card-item-bonuses'>
                                        +{sumBonuses} Б
                                    </p>
                                }
                            </p>
                        </div>
                    </div>
                }

                {termsPopupElem &&  termsPopupElem}
            </div>
        </div>

    )
}

export default SupplierCard;