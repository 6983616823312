import React, {useContext, useEffect, useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import {FreeMode, Pagination} from "swiper";
import {useNavigate} from "react-router-dom";
import {Context} from "../../../../index";
import ProductCard from "../../../customUI/ProductCard/ProductCard";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import { LoadingOutlined } from '@ant-design/icons';
import {ICatalogCategory} from "../../../../models/Catalog/Catalog";
import {Spin} from "antd";



interface Props {
    category: ICatalogCategory,
    loadMoreProducts: (categoryId: string) => void,
    loadingCategoryId: string,
    selectedView: number,
    onSelectCategory: (categoryId: string) => void
}

const SwiperCatalog = ({category, loadMoreProducts, loadingCategoryId, onSelectCategory}:Props) => {
    const {store} = useContext(Context)

    let ListCards: any = [];
    const navigate = useNavigate()
    const [sliderShowUntilIndex, setSliderShowUntilIndex] = useState<number>(10);

    const [categoryData, setCategoryData] = useState<ICatalogCategory>(category);

    const goCategory = () => {
        onSelectCategory(category.categoryId??"")
    }

    return (
        <>
            <div className={"categories-div"} onClick={() => goCategory()}>
                <p className={"tag-name"}>{category.name}<img/></p>
            </div>
            <Swiper
                key={category.categoryId}
                slidesPerView={"auto"}
                pagination={{
                    clickable: true,
                }}
                modules={[FreeMode, Pagination]}
                className="productSwiper"
            >
                {category.products.map((p, index) => {
                    return <SwiperSlide key={index + p.productId}>
                        <ProductCard
                            isAuth={store.isAuth}
                            key={index + p.productId}
                            item={p}
                        />
                    </SwiperSlide>
                })}

                {category.hasMoreProducts ?
                    loadingCategoryId == category.categoryId?
                    <SwiperSlide>
                        <Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin/>}/>
                    </SwiperSlide>
                    :
                    <SwiperSlide onClick={() => {
                        loadMoreProducts(category.categoryId??"")
                    }}>
                        <div className={"swiperPlusContainer"}>
                            <img src="/Pictures/plus.svg"/>
                        </div>
                    </SwiperSlide>
                    : <></>
                }
            </Swiper>
        </>
    );
};

export default SwiperCatalog;