import { Swiper, SwiperSlide } from "swiper/react";

// Import CustomSwiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./CustomSwiperSupplier.css"

// import required modules
import { FreeMode, Pagination } from "swiper";
import {ICustomSwiperSupplier} from "../../../models/models";
import ProductCardSupplier from "./ProductCardSupplier/ProductCardSupplier";

export default function CustomSwiperSupplier({data}:ICustomSwiperSupplier) {
    let ListCards:any = [];
    if(data)
        if(data.length!=0)
            ListCards = data.map((card)=>{
                if("visible" in card ? card.visible:true) {
                    return (
                            <SwiperSlide style={{width:"auto",height:"auto"}}>
                                <ProductCardSupplier
                                    key={"rootId" in card ? card.rootId : "id"
                                    in card ? card.id : "productId"
                                    in card ? card.productId :"0"}
                                    item={card}
                                    rootId={"rootId" in card ? card.rootId :"0"}
                                    productId={"productId" in card ? card.productId :"0"}
                                    questionId={"questionId" in card ? card.questionId :"0"}
                                    linkToReview={"linkToReview" in card ? card.linkToReview : undefined}
                                />
                            </SwiperSlide>
                    )
                }

            })
    return (<>
            {data&&data.length!=0&&
                <div className="supplier">
                    <Swiper
                        slidesPerView={"auto"}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode, Pagination]}
                        className="productSwiper"
                    >
                        {ListCards}
                    </Swiper>
                </div>
            }
        </>
    );
}