import React from 'react';
import {Table, TablePaginationConfig} from 'antd';
import './CustomAntTable.css';
import {randomInt} from "node:crypto";

interface CustomAntTableProps {
    data: any[],
    columns: any,
    fontSize?: string,
    pagination?: any,
    expandable?: {
        expandedRowRender?: (record: any) => React.ReactNode,
        defaultExpandAllRows?: boolean,
        showExpandColumn?: boolean
    },
    footer?: any,
    scroll?: {x?: any; y?: any},
    loading?: boolean,
    onChange?: (pagination: TablePaginationConfig, filters: any, sorter: any) => void;
    addClassName?: string
}

const CustomAntTable = ({ data, columns, fontSize = '14px', pagination = false, expandable, footer, scroll, loading=false, onChange, addClassName}: CustomAntTableProps) => {
    return (
        <Table
            loading={loading}
            className={`custom-ant-table ${addClassName}`}
            columns={columns}
            dataSource={data.map((d: any, idx: any) => ({ ...d, key: idx }))}
            pagination={pagination}
            scroll={scroll ?? { x: "max-content" }}
            expandable={expandable}
            footer={footer}
            onChange={onChange} // для пагинации
        />
    );
};

export default CustomAntTable;
