import React from 'react';
import CustomAntTable from "../../../customUI/CustomAntTable/CustomAntTable";

interface IDebts {
    bonusPointsDebtsSellerId: string,
    buyerFirstName: string,
    buyerId: string,
    buyerLastName: string,
    buyerVatherName: string,
    orderId: string,
    sellerId: string,
    totalValue: number
}

interface IBonusesDebtsTable {
    debts: IDebts[]
}

const BonusesDebtsTable = ({debts}: IBonusesDebtsTable) => {
    const columns = [
        {
            title: "№",
            dataIndex: "index",
            render: (_: any, record: any, index: number) => index + 1,
            width: 50, // Установлено фиксированное значение (например, 200px)
        },
        {
            title: "Косметолог",
            dataIndex: "buyerName",
            render: (_: any, record: any) => `${record.buyerLastName} ${record.buyerFirstName[0]}. ${record.buyerVatherName[0]}.`,
        },
        {
            title: "№ Заказа",
            dataIndex: "orderId",
        },
        {
            title: "Бонусы",
            dataIndex: "totalValue",
        },
    ]

    return (
        <div className={"bonusesDebtsTable"}>
            <div className={"bonusesDebtsTable-header"}>
                Неотправленные бонусы за заказы
            </div>

            <CustomAntTable data={debts} columns={columns}/>
        </div>
    );
};

export default BonusesDebtsTable;