import "./FavoriteProductCard.css";
import {ICard} from "../../../models/product/productCard";
import React from "react";
import Favorite from "../Favorite/Favorite";
import {Ru} from "../../../functions/functions";
import ButtonToCart from "./buttonToCart/ButtonToCart";
import {CustomIMG} from "../CustomIMG/CustomIMG";

interface ISwiperItemProps {
	item: ICard;
	isAuth: boolean;
	style: string;
	handlerForShow: (level2: string, id: string) => void;
}

function FavoriteProductCard({item, isAuth, style, handlerForShow}: ISwiperItemProps) {
	
	return (
		<>
			<div className={!isAuth ? style : "product_wrap-favorites product_wrap-favorites-extended"}>
				<div id="product_and_heart-favorites">
					<CustomIMG id="img_of_product-favorites" src={item.image}/>
					{isAuth &&
						<div className="favorite-heart">
							<Favorite
								favorite={item?.onFavorite}
								id={item.productId}
								favoriteHandler={handlerForShow}
							/>
						</div>
					}
				</div>
				<div id="description_containter-favorites">
					<span id="description_of_product-favorites">{item.name_of_product} </span>
				</div>
				<div className={"price-div-favorites"}>
					<div id="price1_and_amount-favorites">
            {/*            <span*/}
	          {/*              className={`${item.discount ? "price1-favorites" : "price2-favorites"}`}*/}
	          {/*              style={!item.discount ? {position: "relative", top: "13px"} : {}}*/}
            {/*            >*/}
            {/*                {Ru(item.price1)}*/}
            {/*            </span>*/}
						{/*<span*/}
						{/*	id="amount-favorites"*/}
						{/*	style={{position: "relative", top: "13px"}}*/}
						{/*>*/}
            {/*                {item.amount} шт.*/}
            {/*            </span>*/}
						{item.visibleMaskPrice ?
							<div style={{maxWidth: 70}}>уточняйте цену</div>
							:
							<div className={"productCard-price"}>
								<div>
									{item.discount
										? Ru(item.price2 ?? 0)
										: Ru(item.price1 ?? 0)}
								</div>
								<div className={"productCard-discount"}>
									{item.discount && !item.visibleMaskPrice && Ru(item.price1 ?? 0)}
								</div>
							</div>
						}

						<div className={"productCard-amount-bonuses-container"}>
							{item.visibleMaskAmount ?
								<div style={{textAlign: "right", maxWidth: 52}}>под заказ</div>
								:
								<div className={"productCard-amount"}>
									{item.amount} шт.
								</div>
							}

							{(item.bonuses > 0) &&
								<div className={"productCard-bonuses"}>
									+{item.bonuses} б
								</div>
							}

						</div>
					</div>
					
					
{/*
					{item.discount && <span className="price2-favorites">{Ru(item.price2)}</span>}
*/}
				</div>
				<div id={"supply-company-div-favorites"}>
					{/*<span id="text_supply_company-favorites">{item.supply_company}</span>*/}
					{/*{item.image_supply_company && <CustomIMG id="img_supply_company-favorites" src={item.image_supply_company}/>}*/}
					<img
						id={"productCard-1SConnected-img"}
						src={`${
							item.isConnected1S
								? "/Pictures/CheckGreen.svg"
								: "/Pictures/ExclamRed.svg"
						}`}
					/>
					<span id="text_supply_company">
                            {item.supply_company}
                        </span>
					
					{item.image_supply_company && (
						<CustomIMG
							id="img_supply_company"
							src={item.image_supply_company}
						/>
					)}
				</div>
				{isAuth && <ButtonToCart item={item}/>}
			</div>
		</>
	)
}

export default FavoriteProductCard;