import {Link, useNavigate, useParams} from "react-router-dom";
import "./SupplierOrderPage.css"
import React, {useContext, useEffect, useState} from "react";
import {CompareDates, getParsedDate, Ru} from "../../../../functions/functions";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {Context} from "../../../../index";
import FunctionButton from "../../../customUI/FunctionButton/FunctionButton";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import OrderStatus from "../../../customUI/OrderStatus/OrderStatus";
import {OrderProductStatusEnum, OrderStatusEnum} from "../../../../functions/Order/OrderFunctions";

export default function SupplierOrderPage() {

    const {store} = useContext(Context);

    const FunctionButtonStyle: React.CSSProperties = {
        fontSize: 13,
        lineHeight: "16px",
        borderRadius: 7,
        float: "right",
    };

    const PaymentOptions: { name: string; id: number; }[] =
        [{name: "Наличные", id: 1},
            {name: "Банковская карта", id: 2},
            {name: "Бонусы", id: 3},
            {name: "Со счета", id: 4},
            {name: "QR-код", id: 5},
            {name: "По терминалу", id: 6}];

    const [paymentButtonText, setPaymentButtonText] = useState("Выбрать способ оплаты");
    const [DataFromMyOrders, SetDataFromMyOrders] = useState<any>(null);
    const [statusOrder, setStatusOrder] = useState<number>(-1)
    const [StyleOptions, setStyleOptions] = useState<{
        style: string,
        picture: string,
        time: string,
        title: string
    }[]>([]);
    const navigate = useNavigate();
    const params = useParams();

    const getOrderData = () => {
        store.DataLoadingON();
        CabinetSellerService.getOrder(params.id || "")
            .then((data) => {
                if (data.status === 200) {
                    SetDataFromMyOrders(data.data);
                    setStatusOrder(data.data.historyStatuses[data.data.historyStatuses.length - 1].status)
                    if (data.data.historyStatuses[data.data.historyStatuses.length - 1].statusName == "Выдан" && !data.data.payed) {
                        setPaymentButtonText("Ожидает оплаты");
                    } else if (data.data.payed) {
                        setPaymentButtonText("Оплачено");
                    }
                    setStyleOptions(styleLogic(data.data));
                    for (const key2 in PaymentOptions) {
                        if (PaymentOptions[key2].id === data.data.paymentOption) {
                            setPaymentButtonText(PaymentOptions[key2].name);
                        }
                    }
                }
            })
            .catch(() => store.ErrorON())
            .finally(() => store.DataLoadingOFF());
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getOrderData()
    }, [params.id, store]);

    const TotalPrice = DataFromMyOrders?.orderProducts.reduce((sum: number, item: any) => {
        if(item.historyStatuses && item.historyStatuses[item.historyStatuses.length - 1]?.status !== 1){
            sum += item.price * item.amount
        }
        return sum;
    }, 0);

    const TotalBonuses = DataFromMyOrders?.orderProducts.reduce((bonuses: number, item: any) => {
        if(item.historyStatuses && item.historyStatuses[item.historyStatuses.length - 1]?.status !== 1){
            bonuses += item.bonuses * item.amount
        }
        return bonuses;
    }, 0);

    const TotalPriceFormatted = Ru(TotalPrice || 0);

    const dateForDelivery = DataFromMyOrders && getParsedDate(CompareDates(DataFromMyOrders.orderProducts.map((item: any) => item.deliveryDate)), true, false);
    const AdressForDelivery = DataFromMyOrders?.destination ? DataFromMyOrders?.destination?.city + ', ул. ' + (DataFromMyOrders?.destination?.street) + ', д. ' + (DataFromMyOrders?.destination?.number) : 'Не указан';

    const styleLogic = (data: any) => {
        let StyleOptions = [
            {style: 'myorderpage-green-status', picture: "/Pictures/VectorOpenOrder.svg", title: "Открыт", time: ''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorAccepted.svg", title: "Принят", time: ''},
            {
                style: 'myorderpage-black-status',
                picture: "/Pictures/VectorReadyToGet.svg",
                title: "Готов к выдаче",
                time: ''
            },
            {style: 'myorderpage-black-status', picture: "/Pictures/Доставка.svg", title: "Доставка", time: ''},
            {style: 'myorderpage-black-status', picture: "/Pictures/Выдан.svg", title: "Выдан", time: ''},
            {style: 'myorderpage-black-status', picture: "/Pictures/VectorPayedOrder.svg", title: "Оплачен", time: ''}
        ];

        if (data?.statusChanges) {
            data.statusChanges.forEach((statusChange: any) => {
                const {status, timestamp} = statusChange;
                let statusIndex;
                let statusStyle = 'myorderpage-black-status';

                // Определим индекс и цвет стиля для текущего статуса
                switch (status) {
                    case "Открыт":
                        statusIndex = 0;
                        break;
                    case "Принят":
                        statusIndex = 1;
                        break;
                    case "Готов к выдаче":
                        statusIndex = 2;
                        break;
                    case "Доставка":
                        statusIndex = 3;
                        break;
                    case "Выдан":
                        statusIndex = 4;
                        break;
                    case "Оплачен":
                        statusIndex = 5;
                        break;
                    default:
                        return;
                }

                if (timestamp) {
                    StyleOptions[statusIndex].time = getParsedDate(timestamp, false, true);
                    StyleOptions[statusIndex].style = statusStyle;
                }
            });
        }

        return StyleOptions;
    };

    const renderStatusOrButton = (productStatus: number, orderProductId: string) => {
        if (productStatus === OrderProductStatusEnum.ApproveCanceled) {
            return <div style={{color: "#8E4646", textDecoration: "none"}}>Отменён</div>;
        }

        if (productStatus === OrderProductStatusEnum.CanceledBuyer) {
            return <span className="myorderpage-fourth-column-red" onClick={() => cancelOrderProduct(orderProductId)}>
                    Подтвердить отмену
                </span>
        }

        return null;
    };

    const cancelOrder = () => {
        store.showDialog("Подтвердить отмену всего заказа?", true, "Отмена заказа")
            .then(() => {
                if (store.dialog.dialogResult) {
                    let data = {
                        Info1: DataFromMyOrders.orderId,
                    }
                    store.DataLoadingON()
                    CabinetSellerService.cancelOrderProducts(data)
                        .then(() => getOrderData())
                        .catch(() => store.showDialog("Что-то пошло не так"))
                        .finally(() => store.DataLoadingOFF())
                }
            })
    }

    const cancelOrderProduct = (orderProductId: string) => {
        store.showDialog("Подтвердить отмену позиции?", true, "Отмена позиции")
            .then(() => {
                if (store.dialog.dialogResult) {
                    let data = {
                        Info1: DataFromMyOrders.orderId,
                        Info2: orderProductId
                    }
                    store.DataLoadingON()
                    CabinetSellerService.cancelOrderProduct(data)
                        .then(() => getOrderData())
                        .catch(() => store.showDialog("Что-то пошло не так"))
                        .finally(() => store.DataLoadingOFF())
                }
            })
    }

    return (
        <>
            <CustomNavigateHeader
                title={`Заказ №${DataFromMyOrders?.orderId}`}
                trashClickHandler={() => null}
                withTrash={false}
            />

            <p className="myorderpage-upper-title">Информация о заказе</p>

            <OrderStatus
                historyStatuses={DataFromMyOrders?.historyStatuses}
                deliveryType={DataFromMyOrders?.destination?.destinationOption == 2 ? "delivery" : "pickup"}
                isPayed={DataFromMyOrders?.payed}
                payedDate={DataFromMyOrders?.payedDate}
            />

            <div className="myorderpage-main-div">
                        <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Дата оформления заказа:</span>
                            {getParsedDate(DataFromMyOrders?.startDate, true, false)}
                        </span>
                <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Покупатель:</span>
                    {DataFromMyOrders?.buyer?.displayedName || "-"}
                        </span>
                <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Телефон покупателя:</span>
                    {DataFromMyOrders?.buyer?.phoneNumber || "-"}
                        </span>
                <span className="myorderpage-string">
                            <span className="myorderpage-title-string">Тип доставки:</span>
                    {DataFromMyOrders?.destination?.destinationOption == 2 ? "Курьером" : "Самовывоз"}
                        </span>
                <span className="myorderpage-string">
                    <span className="myorderpage-title-string">
                        {DataFromMyOrders?.destination?.destinationOption == 2 ? "Адрес доставки:" : "Адрес самовывоза:"}
                    </span>

                    {AdressForDelivery}

                    {DataFromMyOrders?.destination?.pickUpBonuses &&
                        <b style={{color: "#01aba2", marginLeft: "5px"}}>
                            (+{DataFromMyOrders?.destination?.pickUpBonuses} Б)
                        </b>
                    }
                </span>

                <span className="myorderpage-string">
                    <span className="myorderpage-title-string">Желаемое время доставки:</span>

                    {DataFromMyOrders?.startDate && DataFromMyOrders?.endDate ? (
                            getParsedDate(DataFromMyOrders.startDate, true, false) !== getParsedDate(DataFromMyOrders.endDate, true, false) ? (
                                // Если даты совпадают, но время разное - показываем интервал времени
                                <>
                                    {getParsedDate(DataFromMyOrders.startDate, true, false)}, {getParsedDate(DataFromMyOrders.startDate, false, true)} - {getParsedDate(DataFromMyOrders.endDate, false, true)}
                                </>
                            ) : (
                                // Если даты и время совпадают - указываем "в течение дня"
                                `${getParsedDate(DataFromMyOrders.startDate, true, false)}, в течение дня`
                            )
                        )
                        : (
                            // Если даты отсутствуют
                            "-"
                        )}
                </span>

                <div className="myorderpage-order-list">
                    <div className="myorderpage-header-table">
                        <span className="myorderpage-title-string">Товары в заказе:</span>
                        {statusOrder === 8 && DataFromMyOrders?.orderProducts.every((product: any) => product.historyStatuses[product.historyStatuses.length - 1]?.status === 0)
                            && (
                                <div className="myorderpage-cancel-order" onClick={() => cancelOrder()}>
                                    Подтвердить отмену заказа
                                </div>
                            )
                        }

                    </div>
                    <table className="myorderpage-table">
                        {DataFromMyOrders?.orderProducts.map((item: any) => (
                            <tr key={item.orderProductId}
                                style={item.cancelled ? {backgroundColor: "rgb(153 153 153 / 34%)"} : {}}
                            >
                                <td className="myorderpage-columns myorderpage-first-column">{item.name}</td>
                                <td className="myorderpage-columns myorderpage-second-column">{item.amount} шт.</td>
                                <td className="myorderpage-columns myorderpage-third-column">
                                    {Intl.NumberFormat("ru", {
                                        style: "currency",
                                        currency: "RUB"
                                    }).format(item.price * item.amount)}
                                    {(item.historyStatuses && item.historyStatuses[item.historyStatuses.length - 1]?.status !== 1) && item.bonuses > 0 &&
                                        <div className={"myorderpage-bonuses"}>
                                            +{item.bonuses * item.amount} Б
                                        </div>
                                    }
                                </td>
                                <td className="myorderpage-columns myorderpage-fourth-column">
                                    {renderStatusOrButton(item?.historyStatuses?.slice(-1)[0]?.status, item.orderProductId)}
                                </td>
                            </tr>
                        ))}
                    </table>
                </div>


                {statusOrder !== 9 &&
                    <div id="myorderpage-sum-of-order">
                        <span>Итого:</span>
                        <img id="img_dashed" src="/Pictures/Line 12.png" alt="line"/>
                        <span id="myorderpage-price-of-order">
                                {TotalPriceFormatted}
                            {TotalBonuses > 0 &&
                                <div className={"myorders-bonuses"}>
                                    +{TotalBonuses + DataFromMyOrders?.destination?.pickUpBonuses ?? 0} Б
                                </div>
                            }
                    </span>
                    </div>
                }

                <span className="myorderpage-title-string">
                    Комментарий к заказу
                </span>

                <span className="myorderpage-comment">
                    {DataFromMyOrders?.comment || "Комментарий к заказу не указан"}
                </span>

                <span className="myorderpage-title-string">
                    Комментарий к доставке
                </span>

                <span className="myorderpage-comment">
                    {DataFromMyOrders?.destination?.comment || "Комментарий к доставке не указан"}
                </span>

                <div className="myorderpage_bottom_buttons">
                    <FunctionButton
                        text={"Начать чат с покупателем"}
                        onClickHandler={() => {
                            navigate(SpaRoutes.SUPPLIER_CHAT + `/:${DataFromMyOrders?.buyerId}/:1/:0`)
                        }
                        }
                        style={FunctionButtonStyle}
                    />
                    <Link
                        to={SpaRoutes.Seller.OrderPayment + `/${DataFromMyOrders?.orderId}/${TotalPriceFormatted}/${DataFromMyOrders?.payed}/${DataFromMyOrders?.paymentOption}`}>
                        <div className={"myorderpage-payment-button"}>
                            <FunctionButton
                                text={paymentButtonText}
                                onClickHandler={() => {
                                }}
                                style={FunctionButtonStyle}
                            />
                        </div>
                    </Link>
                </div>
            </div>
        </>
    );
}