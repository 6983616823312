import {AxiosResponse} from "axios";
import {RegionsResponse} from "../models/response/RegionsResponse";
import $api from "../api/axios";
const {ApiRoutes: { Catalog }} = require ("../Routes/apiRoutes");

export default class CatalogService {
    static async getCatalog(filters: any):Promise<AxiosResponse> {
        return $api.post(Catalog.GetCatalog, filters);
    }
    static async loadMoreProducts(filters: any):Promise<AxiosResponse> {
        return $api.post(Catalog.LoadMoreProducts, filters);
    }
    static async getCatalogFilters(filters: any):Promise<AxiosResponse> {
        return $api.post(Catalog.GetCatalogFilters, filters);
    }
    static async getCategories():Promise<AxiosResponse> {
        return $api.get(Catalog.GetCategories);
    }
}