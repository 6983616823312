import React, { useState, useEffect } from "react";
import './WarehouseFilterBox.css'; // Стили для компонента

// Интерфейс для данных склада
interface Magazine {
    dropAdressId: string;
    city: string;
    cityId: string;
    regionId: string;
    streetAndNumber: string;
    region: string;
    isVirtual: boolean;
    virtualRegion?: string | null;
    virtualRegionId?: string | null;
    forMagazineId?: string;
}

interface WarehouseFilterBoxProps {
    magazines: Magazine[];
    onFilter: (isVirtual?: boolean, regionId?: string | null | undefined, warehouseId?: string) => void;
}

const WarehouseFilterBox: React.FC<WarehouseFilterBoxProps> = ({ magazines, onFilter }) => {
    const [regionsWithWarehouses, setRegionsWithWarehouses] = useState<Record<string, Magazine[]>>({});
    const [selectedRegion, setSelectedRegion] = useState<string | null>(null);
    const [selectedWarehouse, setSelectedWarehouse] = useState<string | null>(null);

    useEffect(() => {
        // Группируем склады по регионам
        const groupedRegions: Record<string, Magazine[]> = {};

        magazines.forEach((magazine) => {
            const regionKey = magazine.isVirtual && magazine.virtualRegion
                ? magazine.virtualRegion
                : magazine.region;

            if (!groupedRegions[regionKey]) {
                groupedRegions[regionKey] = [];
            }
            groupedRegions[regionKey].push(magazine);
        });

        setRegionsWithWarehouses(groupedRegions);
    }, [magazines]);

    // Выбор региона и сброс выбранного склада
    const handleRegionSelect = (isVirtual: boolean | undefined, region: string) => {
        setSelectedRegion(region);
        setSelectedWarehouse(null);
        onFilter(isVirtual, region, undefined);
    };

    // Выбор склада
    const handleWarehouseSelect = (warehouse: Magazine) => {
        setSelectedWarehouse(warehouse.dropAdressId);
        const regionId = warehouse.isVirtual && warehouse.virtualRegionId ? warehouse.virtualRegionId : warehouse.regionId;
        onFilter(warehouse.isVirtual, warehouse.isVirtual? warehouse.virtualRegion : warehouse.region, warehouse.dropAdressId);
    };

    // Сброс выбранного региона и склада
    const resetSelection = () => {
        setSelectedRegion(null);
        setSelectedWarehouse(null);
        onFilter(undefined, "", ""); // Сбрасываем фильтр, передавая пустые значения
    };

    return (
        <div className="warehouse-filter-box">
            <div className="warehouse-filter-box-title">
                Фильтрация по регионам и складам
            </div>

            {selectedRegion ? (
                // Отображение выбранного региона и списка складов
                <div className="selection-display">

                    <div className="warehouse-filter">
                        <div className="selected-city">
                            {selectedRegion}
                            <span className="reset-icon" onClick={resetSelection}>&times;</span>
                        </div>
                        {regionsWithWarehouses[selectedRegion]?.map((warehouse) => (
                            <div
                                key={warehouse.dropAdressId}
                                className={`warehouse-item ${warehouse.dropAdressId === selectedWarehouse ? 'active' : ''}`}
                                onClick={() => handleWarehouseSelect(warehouse)}
                            >
                                {warehouse.city}, {warehouse.streetAndNumber} (ID: {warehouse.dropAdressId})
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="city-filter">
                    {Object.keys(regionsWithWarehouses).map((region, index) => {
                        const regionObj = magazines.find(mag => (mag.isVirtual && mag.virtualRegion === region) || mag.region === region);

                        return (
                            <div
                                key={region + index}
                                className={`city-item ${region === selectedRegion ? 'active' : ''}`}
                                onClick={() => handleRegionSelect(regionObj?.isVirtual, region)}
                            >
                                {regionObj?.isVirtual && `(Вирт.)`} {region}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default WarehouseFilterBox;
