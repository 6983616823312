import React, {useContext, useEffect, useState} from "react";
import {Button, Popconfirm, Table} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import {Link, useParams} from "react-router-dom";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import AdminService from "../../../../services/AdminService";
import {Context} from "../../../../index";
import './magazineTable.css';
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CustomAntTable from "../../../customUI/CustomAntTable/CustomAntTable";
import CustomAntPopconfirm from "../../../customUI/CustomAntPopconfirm/CustomAntPopconfirm";
import TermsPopup from "./TermsPopup/TermsPopup";
import DeliveryPointPopup from "./DeliveryPointPopup/DeliveryPointPopup";
import {Terms} from "../../../../models/response/getProductInfoResponse";

interface DataType {
    key: string;
    region: string;
    index: string;
    address: string;
    terms: React.ReactNode;
    action?: React.ReactNode;
    deliveryPoints?: string[];
}

interface RegionData {
    region: string;
    cities: CityData[];
}

interface CityData {
    name: string;
    id: string;
    shops: DataType[];
}

const SupplierMagazines = () => {
    const [regions, setRegions] = useState<RegionData[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const {store} = useContext(Context);
    const {supplierId} = useParams();
    const [termsPopup, setTermsPopup] = useState<React.ReactElement | null>(null);
    const [deliveryPointPopup, setDeliveryPointPopup] = useState<React.ReactElement | null>(null);

    const columns: ColumnsType<DataType> = [
        {
            title: '№ склада',
            dataIndex: 'index',
            key: 'index',
            width: 100,
        },
        {
            title: 'Адрес склада',
            dataIndex: 'address',
            key: 'address',
            width: 450,
        },
        {
            title: 'Доставка и самовывоз',
            dataIndex: 'terms',
            key: 'terms',
            width: 200
        },
        {
            title: '',
            dataIndex: 'action',
            key: 'action',
            width: 200,
            colSpan: 1
        },
    ];

    const sellerGet = () => {
        store.DataLoadingON();
        return CabinetSellerService.getAllMagazinesInfo()
            .catch()
            .finally(() => store.DataLoadingOFF());
    };

    const adminGet = (id: string) => {
        store.DataLoadingON();
        return AdminService.getSupplierMagazines(id)
            .catch()
            .finally(() => store.DataLoadingOFF());
    };

    const getMagazines = async () => {
        try {
            const GetSupplierMagazines = store.roles.includes('Admin') && supplierId
                ? adminGet(supplierId)
                : sellerGet();

            const response = await GetSupplierMagazines;
            const groupedByRegion: { [region: string]: RegionData } = {};

            response.data.shops.forEach((shop: any) => {
                // Определяем, является ли склад виртуальным
                const isVirtual = shop.isVirtual;
                const regionKey = isVirtual ? `${shop.virtualRegion} (Виртуальные)` : shop.region;

                if (!groupedByRegion[regionKey]) {
                    groupedByRegion[regionKey] = {
                        region: regionKey,
                        cities: []
                    };
                }

                const region = groupedByRegion[regionKey];

                let city = region.cities.find((city) => city.id === (isVirtual ? shop.virtualRegionId : shop.cityId));
                if (!city) {
                    city = {
                        name: isVirtual ? shop.virtualRegion : shop.city,
                        id: isVirtual ? shop.virtualRegionId : shop.cityId,
                        shops: []
                    };
                    region.cities.push(city);
                }

                // Добавляем информацию о складе в соответствующий город
                city.shops.push({
                    key: shop.dropAdressId,
                    index: shop.dropAdressId,
                    address: isVirtual
                        ? `Виртуальный от склада ${shop.region}, ${shop.city}, ${shop.streetAndNumber} (${shop.forMagazineId})`
                        : shop.streetAndNumber,
                    terms: (
                        <div className={"supplierMagazines-terms-cell"}>
                            <div className={"supplierMagazines-terms-cell-content"}>
                                <div className={"supplierMagazines-terms-cell-delivery"}>
                                   Условия доставки: {shop.terms && shop.terms.visible ? " Есть" : " Нет"}
                                </div>
                                <div className={"supplierMagazines-terms-cell-pickUp"}>
                                    При самовывозе <b style={{color: "#01aba2"}}>+{(shop.terms && shop.terms.pickUpBonuses)??0} Б</b> {shop.terms && shop.terms.pickUpBonusesStartSum > 0&& `от ${shop.terms.pickUpBonusesStartSum}₽`}
                                </div>
                            </div>

                            <img src={"/Pictures/edit.svg"} onClick={() => getTermsPopup(shop.dropAdressId, shop.terms)} />
                        </div>
                    ),
                    region: isVirtual ? shop.virtualRegion : shop.region,
                    action: (
                        <div className="supplierMagazines-tableButtons">
                            {!isVirtual &&
                                <Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${-1}` + `/${shop.dropAdressId}`}>
                                    <div className={"supplierMagazines-edit"}>Создать виртуальный</div>
                                </Link>
                            }
                            <Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${shop.dropAdressId}` + `/${-1}`}>
                                <div className={"supplierMagazines-edit"}>Редактировать</div>
                            </Link>
                            <CustomAntPopconfirm title={"Вы точно хотите удалить склад?"} onConfirm={() => { handleDelete(shop.dropAdressId) }}>
                                <div className={"supplierMagazines-delete"}>
                                    <img src={"/Pictures/trashWhite.svg"} alt="Удалить" />
                                    <img src={"/Pictures/trashRed.svg"} alt="Удалить" />
                                </div>
                            </CustomAntPopconfirm>
                        </div>
                    ),
                    deliveryPoints: shop.deliveryPoints || [],
                });
            });

            setRegions(Object.values(groupedByRegion));
        } catch (error) {
            console.error(error);
        }
    };

    const getTermsPopup = (id:string, data: any) => {
        setTermsPopup(
            <TermsPopup
                data={data}
                dropAddressId={id}
                onClose={() => setTermsPopup(null)}
                onUpdate={() => getMagazines()}
            />
        )
    }

    const getDeliveryPointPopup = (id:string, region: string, data?: any) => {
        setDeliveryPointPopup(
            <DeliveryPointPopup
                dropAddressId={id}
                region={region}
                onClose={() => setDeliveryPointPopup(null)}
                data={data}
                onUpdate={() => getMagazines()}
            />
        )
    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getMagazines();
    }, []);

    const handleDelete = (id: string) => {
        // Логика удаления склада
        CabinetSellerService.deleteMagazine(id).then((response) => {
            if (response.status === 200) {
                setRegions((prevRegions) =>
                    prevRegions.map((region) => ({
                        ...region,
                        cities: region.cities.map((city) => ({
                            ...city,
                            shops: city.shops.filter((shop) => shop.key !== id),
                        }))
                    }))
                );
            }
        });
    };

    const filterData = (data: RegionData[], searchTerm: string) => {
        if (!searchTerm) return data;

        const lowercasedSearchTerm = searchTerm.toLowerCase();

        return data
            .filter(region =>
                region.region.toLowerCase().includes(lowercasedSearchTerm) ||  // Фильтрация по региону
                region.cities.some(city =>  // Фильтрация по городам и магазинам
                    city.name.toLowerCase().includes(lowercasedSearchTerm) ||
                    city.shops.some(shop =>
                        shop.index.toLowerCase().includes(lowercasedSearchTerm) ||
                        shop.address.toLowerCase().includes(lowercasedSearchTerm)
                    )
                )
            )
            .map(region => {
                if (region.region.toLowerCase().includes(lowercasedSearchTerm)) {
                    return region;
                }

                return {
                    ...region,
                    cities: region.cities
                        .map(city => {
                            if (city.name.toLowerCase().includes(lowercasedSearchTerm)) {
                                return city;
                            } else {
                                return {
                                    ...city,
                                    shops: city.shops.filter(
                                        shop =>
                                            shop.index.toLowerCase().includes(lowercasedSearchTerm) ||
                                            shop.address.toLowerCase().includes(lowercasedSearchTerm)
                                    )
                                };
                            }
                        })
                        .filter(city => city.shops.length > 0)
                };
            })
            .filter(region => region.cities.length > 0);
    };

    const changeHandler = (val: string) => {
        setSearchTerm(val);
    };

    const filteredRegions = filterData(regions, searchTerm);

    const deleteDeliveryPoint = (dropAddressId: string, deliveryPointId: string) => {
        let data = {
            DropAdressId: dropAddressId,
            DeliveryPointId: deliveryPointId
        }
        store.DataLoadingON()
        CabinetSellerService.deleteDeliveryPoint(data)
            .then(() => {
                alert("Пункт выдачи был удален")
                getMagazines()
            })
            .catch(() => alert("Что-то пошло не так"))
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    return (
        <div className={"supplierMagazines"}>
            <CustomNavigateHeader title={"Склады"} trashClickHandler={() => {
            }} withTrash={false}/>
            <Searchbar
                changeHandler={changeHandler} // Обработчик ввода текста
                initialText={"Найти"}
            />
            <div className={"supplierMagazines-add-container"}>
                <Link to={SpaRoutes.SUP_MAGAZINE_EDIT + `/${-1}` + `/${-1}`}>
                    <div className={"supplierMagazines-add"}>Добавить склад</div>
                </Link>
            </div>


            {filteredRegions.length > 0?  filteredRegions.map((region) => (
                <div key={region.region} className={"supplierMagazines-region"}>
                    <h2>{region.region}</h2>
                    {region.cities.map((city) => (
                        <div key={city.id} className={"supplierMagazines-city"}>
                            <h3>{city.name}</h3>
                            <CustomAntTable
                                columns={columns}
                                data={city.shops}
                                pagination={false}
                                expandable={{
                                    expandedRowRender: (record) =>
                                         (
                                             <div className="supplierMagazines-deliveryPoints-container">
                                                 Пункты выдачи:
                                                 <img
                                                     alt={"Добавить пункт выдачи"}
                                                     src={"/Pictures/SubtractAddPlus.svg"}
                                                     className={"supplierMagazines-deliveryPoints-item-add"}
                                                     onClick={() => getDeliveryPointPopup(record.index, record.region)}
                                                 />
                                                 {
                                                     record.deliveryPoints.map((d: any) => {
                                                         return <div className={"supplierMagazines-deliveryPoints"} key={d.deliveryPointId}>
                                                             {d.name}, {d.city}, {d.street} {d.number}
                                                             <div
                                                                 className={"supplierMagazines-deliveryPoints-button-edit"}
                                                                 onClick={() => getDeliveryPointPopup(record.index, record.region, d)}
                                                             >
                                                                 <img alt={"Редактировать"}
                                                                      src={"/Pictures/edit-3-black.svg"}/>
                                                             </div>
                                                             <CustomAntPopconfirm
                                                                 title={"Вы точно хотите удалить этот пункт выдачи?"}
                                                                 onConfirm={() => deleteDeliveryPoint(record.index, d.deliveryPointId)}
                                                             >
                                                                 <div className={"supplierMagazines-delete"}>
                                                                     <img src={"/Pictures/trashWhite.svg"}
                                                                          alt="Удалить"/>
                                                                     <img src={"/Pictures/trashRed.svg"} alt="Удалить"/>
                                                                 </div>
                                                             </CustomAntPopconfirm>
                                                         </div>
                                                     })
                                                 }
                                             </div>
                                         ),
                                    defaultExpandAllRows: true,
                                    showExpandColumn: false,
                                }}
                            />
                        </div>
                    ))}
                </div>
            ))
            :
                <CustomAntTable
                    columns={columns}
                    data={[]}
                    pagination={false}
                />
            }

            {termsPopup && termsPopup}
            {deliveryPointPopup && deliveryPointPopup}
        </div>
    );
};

export default SupplierMagazines;
