import React, {useContext, useEffect, useState} from 'react';
import styles from './CustomerBonuses.module.scss';
import BonusTransaction from "../../Supplier/Bonuses/BonusTransaction";
import {Link} from "react-router-dom";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {Context} from "../../../../index";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {IBaseInformation, IBonusTransaction} from "../../../../models/models";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {SpaRoutes} from "../../../../Routes/spaRoutes";


const CustomerBonuses = () => {
    const {store} = useContext(Context);

    const [bonusBalance, setBonusBalance] = useState<number>(0)
    const [bonusBalanceHold, setBonusBalanceHold] = useState<number>(0)
    const [dataBonusTransactions, setDataBonusTransactions] = useState<IBonusTransaction[]>()
    const [transactions, setTransactions] = useState<IBonusTransaction[]>()
    const [isLastNegative, setIsLastNegative] = useState(false)
    const [isLastPositive, setIsLastPositive] = useState(false)

    const getData = () => {
        store.DataLoadingON()
        CabinetBuyerService.getBonusBalance()
            .then((r) => {
                setBonusBalance(r.data.balance)
                setBonusBalanceHold(r.data.balanceHold)
                CabinetBuyerService.getBonusTransactions(6)
                    .then(response => {
                        setDataBonusTransactions(response.data)
                    })
                    .catch(error => store.ErrorON())
                    .finally(() => store.DataLoadingOFF())
            })
            .catch(error => {
                store.ErrorON()
                store.DataLoadingOFF()
            })
    }

    const parseDateFromString = (input: any) => {
        input = input.split(" ")
        let date = new Date;
        date.setDate(parseInt(input[0].slice(0, 2)));
        date.setMonth(parseInt(input[0].slice(3, 5)) - 1);
        date.setFullYear(parseInt(input[0].slice(6, 10)));
        date.setHours(parseInt(input[1].slice(0, 2)));
        date.setMinutes(parseInt(input[1].slice(3, 5)));
        date.setSeconds(parseInt(input[1].slice(6, 10)));
        return date;
    }

    const getLast = () => {
        if (dataBonusTransactions) {
            const arr = dataBonusTransactions.slice()
            setTransactions(arr
                .sort(function (a, b): any {
                    var dateA = new Date(parseDateFromString(a.date)).getTime()
                    var dateB = new Date(parseDateFromString(b.date)).getTime()
                    return dateB - dateA
                }).slice(0, 6)
            )
        }
    }

    useEffect(() => {
        if (isLastNegative) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value < 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else if (isLastPositive) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value > 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else {
            getLast()
        }
    }, [isLastNegative, isLastPositive, dataBonusTransactions])

    useEffect(() => {
        window.scrollTo(0,0)

        getData()

    }, []);

    return (
        <div className={styles.container}>
            <CustomNavigateHeader title={"Баланс бонусов"} type={"back"}  />

            <div className={styles.mainContent}>
                <div className={styles.quantityCard}>
                    <div className={styles.quantity}>{bonusBalance.toFixed(2)}</div>
                    <div className={styles.quantityText}>бонусов</div>
                    <div className={styles.quantityHold}>{bonusBalanceHold.toFixed(2)}</div>
                    <div className={styles.quantityText}>на удержании</div>
                </div>

                <div className={styles.historyOperationsContainer}>
                    <div className={styles.historyOperationsHeader}>
                        История операций:
                        <div className={styles.historyOperationsButtons}>
                            <button data-isactive={isLastNegative}
                                    onClick={() => {
                                        setIsLastNegative(!isLastNegative)
                                        setIsLastPositive(false)
                                    }
                                    }>Расходы
                                {isLastNegative && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                            </button>
                            <button data-isactive={isLastPositive}
                                    onClick={() => {
                                        setIsLastPositive(!isLastPositive)
                                        setIsLastNegative(false)
                                    }
                                    }>Зачисления
                                {isLastPositive && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                            </button>

                        </div>
                    </div>

                    <BonusTransaction transactions={transactions}/>

                    {transactions?.length != 0 &&
                        <div className={styles.historyOperationsWatchAll}>
                            <Link to={SpaRoutes.Customer.BonusesTransactionHistory}>
                                Смотреть все
                            </Link>
                        </div>
                    }
                </div>
            </div>

        </div>
    );
};

export default CustomerBonuses;