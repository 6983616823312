import "./OrderFormation.css"
import OrderFormationItems from "./OrderFormationItems";
import React, {useCallback, useContext, useEffect, useState} from "react";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {IOFPost, IOrder, IOrderFormation} from "../../../../models/OrderFormation/IOrderFormation";
import BottomCartButton from "../../../customUI/BottomCartButton/BottomCartButton";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {ModalContext} from "../../../../context/ModalContext";
import {OrderFormationModal} from "./OrderFormationModal/OrderFormationModal";
import {useNavigate} from "react-router-dom";
import {TimeTo} from "../../../../data/OrderDeliveryChoose/TimeTo";
import {TimeFrom} from "../../../../data/OrderDeliveryChoose/TimeFrom";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {ISupplierAdress} from "../../../../models/supplier/supplier";

const OrderFormation = () => {
    const {store} = useContext(Context);
    const [items, setItems] = useState<IOrder[] | null>();
    const [result, setResult] = useState<IOFPost[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const getOrderFormation = () => {
        store.DataLoadingON()
        CabinetBuyerService.getInvoiceFormation()
            .then((result) => {
                setItems(result.data.suppliersitems);
                const newResult = []
                for (const key in result.data.suppliersitems) {
                    newResult.push({
                            suppliername: result.data.suppliersitems[key].suppliername,
                            supplierId: result.data.suppliersitems[key].supplierID,
                            orderId: result.data.suppliersitems[key].orderId,
                            destinationOption: result.data.suppliersitems[key].adressArray[0].destinationOption,
                            addressId: result.data.suppliersitems[key].addressId,
                            from: result.data.suppliersitems[key].selectedTime ? result.data.suppliersitems[key].from : TimeFrom[0].name,
                            until: result.data.suppliersitems[key].selectedTime ? result.data.suppliersitems[key].to : TimeTo[0].name,
                            comment: result.data.suppliersitems[key].comment,
                            selectedTime: result.data.suppliersitems[key].selectedTime,
                            selectedAdress: result.data.suppliersitems[key].selectedAdress,
                            isDeliveryPoint: result.data.suppliersitems[key].isDeliveryPoint
                        }
                    )
                }
                setResult(newResult)
            })
            .catch((e) => {
                console.log(e)
            })
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    useEffect(() => {
        getOrderFormation()
    }, [])

    function SetAddress(orderId: string, address: ISupplierAdress) {
        setResult(prevState => {
            const newState = [...prevState]
            newState.map((item) => {
                if (item.orderId === orderId) {
                    item.addressId = address.id
                    item.destinationOption = address.destinationOption
                    item.selectedAdress = true
                    item.isDeliveryPoint = address.isDeliveryPoint
                }
            })
            return newState
        })
        setItems(prevState => {
            if (prevState !== null && prevState !== undefined) {
                const newState = [...prevState]
                newState.map((item) => {
                    if (item.orderId === orderId) {
                        item.addressId = address.id
                        item.destinationOption = address.destinationOption
                        item.selectedAdress = true
                        item.isDeliveryPoint = address.isDeliveryPoint
                    }
                })
                return newState
            } else {
                return prevState
            }
        })

        let data = {
            OrderId: orderId,
            destinationOption: address.destinationOption,
            AddressId: address.id,
            IsDeliveryPoint: address.isDeliveryPoint
        }
        store.DataLoadingON()
        CabinetBuyerService.setAdress(data)
            .catch(() => store.showDialog("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    function SetTime(orderId: string | undefined, from: string, until: string) {
        setResult(prevState => {
            const newState = [...prevState]
            newState.map((item) => {
                if (item.orderId === orderId) {
                    item.from = from;
                    item.until = until;
                    item.selectedTime = true;
                }
            })
            return newState
        })
        setItems(prevState => {
            if (prevState !== null && prevState !== undefined) {
                const newState = [...prevState]
                newState.map((item) => {
                    if (item.orderId === orderId) {
                        item.from = from;
                        item.to = until;
                        item.selectedTime = true;
                    }
                })
                return newState
            } else {
                return prevState
            }
        })
        CabinetBuyerService.setTime(orderId, from, until)
    }

    function SetComment(orderId: string | undefined, comment: string) {
        setResult(prevState => {
            const newState = [...prevState]
            newState.map((item) => {
                if (item.orderId === orderId) {
                    item.comment = comment;
                    item.selectedTime = true;
                }
            })
            return newState
        })
        setItems(prevState => {
            if (prevState !== null && prevState !== undefined) {
                const newState = [...prevState]
                newState.map((item) => {
                    if (item.orderId === orderId) {
                        item.comment = comment;
                        item.selectedTime = true;
                    }
                })
                return newState
            } else {
                return prevState
            }
        })
        CabinetBuyerService.setComment(orderId, comment)
    }

    const handleRemoveItem = (index: any, invoiceId: string) => {
        store.showDialog("Вы точно хотите удалить заказ?", true).then(() => {
            if (store.dialog.dialogResult === true) {
                store.DataLoadingON()
                CabinetBuyerService.postOrderFormationDeleteInvoice(invoiceId)
                    .then(() => {
                        getOrderFormation()
                        store.showDialog("Заказ был удален").then(() => {
                        })
                    })
                    .catch(() => store.showDialog("Что-то пошло не так").then(() => {
                    }))
                    .finally()
            }
        })
    };

    const [ListOfItems, setListOfItems] = useState<JSX.Element[]>([])

    useEffect(() => {
        if (items) {
            const updatedList = items.map((item, index) => (
                <OrderFormationItems
                    key={item.invoiceId}
                    supplier={item}
                    orderId={item.orderId}
                    onRemove={() => handleRemoveItem(index, item.orderId)}
                    index={index}
                    SetAddress={SetAddress}
                    SetTime={SetTime}
                    SetComment={SetComment}
                />
            ));
            setListOfItems(updatedList);
        }
    }, [items]);

    let Sum = 0
    items && items.map((supplier) => {
        supplier.suppliercards.map((card) => {
            Sum = Sum + Number(card.discount ? card.price2 : card.price1) * Number(card.orderAmount)
        })
    })
    let SumFormat = new Intl.NumberFormat(
        "ru",
        {style: "currency", currency: "RUB"}
    ).format(Sum);

    const {modal, open, close} = useContext(ModalContext)

    const trashIconHandler = useCallback(() => {
        store.showDialog('На данный момент нет возможности удалить заказ').then(() => {
        })
    }, [])

    const redirectToHistory = () => {
        close();
        navigate("/myOrdersActive");
    }

    const ResSent = async (result: IOFPost[]) => {

        const asyncCycle = async function () {
            let res = true
            for (const item of result) {
                await CabinetBuyerService.postSetStatusOpen({OrderId: item.orderId})
                    .catch(() => res = false)
            }
            return res
        }
        store.DataLoadingON()
        asyncCycle()
            .then(async (res) => {
                if (res) {
                    store.showDialog("Заказ успешно сформирован").then(() => {
                    })
                } else {
                    store.showDialog("Что-то пошло не так").then(() => {
                    })
                }
            })
            .catch(() => store.showDialog("Что-то пошло не так").then(() => {
            }))
            .finally(() => {
                store.DataLoadingOFF()
                let countOrders = items?.length ? items?.length : 0
                if (countOrders - result.length === 0) {
                    navigate(SpaRoutes.MY_ORDERS_ACTIVE)
                } else getOrderFormation()
            })
    }

    return (
        <div style={{paddingBottom: "80px"}}>
            <CustomNavigateHeader
                trashClickHandler={trashIconHandler}
                title={`Формирование заказа ${ListOfItems ? `(${ListOfItems.length})` : ''}`}
                withTrash={false}
            />
            {(!store.isError && !store.isDataLoading && items) &&
                <>
                    {ListOfItems}
                    <div id="sum-of-order">
                        <span>Сумма:</span>
                        <img id="img_dashed" src="/Pictures/Line 12.png"/>
                        {/*<span>{rub1}</span>*/}
                        <span>{SumFormat}</span>
                    </div>
                    <div>
                        <BottomCartButton
                            link={"OrderFormation"}
                            state={result}
                            ServiceFunction={ResSent}
                            forOrderFormation={true}
                        />
                    </div>
                    {/*{modal && <OrderFormationModal handler={redirectToHistory} title="Create new product" onClose={close}>*/}
                    {/*</OrderFormationModal>}*/}
                </>
            }

        </div>
    )
}
export default OrderFormation