import {Link, useNavigate} from "react-router-dom";
import React, {useContext} from "react";
import styles from "./NoProducts.module.scss";
import {Context} from "../../../../index";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
interface Interface {
    tittle?:string;
    text?:string;
    searchText?:string;
}

function NoProducts({tittle,text, searchText}:Interface) {
    const {store} = useContext(Context);
    const navigate = useNavigate()

    return(
        <div className={styles.container}>
            <div className={styles.header}>
                {tittle && <p className={styles.headerH}>{tittle}</p>}
                {text && <p className={styles.headerTitle}>{text}</p>}
            </div>

            <div className={styles.clearFilter} onClick={() => {
                navigate({
                    pathname: SpaRoutes.CATALOG,
                    search: searchText && searchText?.length > 0? `?q=${searchText}` : undefined,
                });
            }}>
                <button onClick={() => {

                }}>
                    <img src={"/Pictures/broomstickBlack.svg"}/>
                    Очистить фильтр
                </button>
            </div>

            <div className={styles.background}>
                <span className={styles.cart}/>
            </div>
        </div>
    )
}

export default NoProducts;