import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../index";
import {CabinetResponse} from "../../../models/response/CabinetResponse";
import CabinetSellerService from "../../../services/CabinetSellerService";
import {BurgerSupplierData} from "../../../data/SupplierBurgerMenu/BurgerSupplierData";
import {Link} from "react-router-dom";
import {SpaRoutes} from "../../../Routes/spaRoutes";
import './SellerHome.css'
import {BeatLoader} from "react-spinners";
import {Loader} from "../../customUI/Loader/Loader";
import {observer} from "mobx-react-lite";

const SellerHome = ()=> {
    const {store} = useContext(Context)

    const [CabinetData, setCabinetData] = useState<CabinetResponse>();
    const [loading, setLoading] = useState(false)

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
        CabinetSellerService.getCabinet()
            .then((data) => {
                setCabinetData(data.data);
            })
            .then(()=>setLoading(true))
    },[])

    

    const BurgerButtons = BurgerSupplierData.map((item) => {
        return (

            <div className="cabinet-sidebar-block" key={item.name}>
                <a className="cabinet-sidebar-item" href={'#' + item.link}>
                    <img width={20} src={item.image} />
                    <p>{item.name}</p>
                    <img
                        width={16}
                        src="/Pictures/cabinet-chevron-right.svg"
                    />
                </a>
            </div>
        );
    });

    return (
        <>
            {!loading?<Loader load={true} />:
                <>
                    <div className="cabinet-wrapper">
                        <div className="cabinet-container">
                            <div className="cabinet-left">
                                <div className="cabinet-sidebar-provider">
                                    <img
                                        src={store.user.image?
                                            store.imgServer+store.user.image:
                                            "https://bozemanschoolsfoundation.org/wp-content/uploads/2018/12/Missing-Person-Image.jpg"}
                                        alt="cabinet-sidebar-provider"
                                        style={{margin:"auto"}}
                                    />
                                    <p>Поставщик</p>
                                    <span>{store.user.lastName}</span>
                                    <span>{store.user.firstname}</span>
                                    <span>{store.user.vatherName}</span>
                                </div>

                                <div className="cabinet-sidebar">
                                    <div className="cabinet-sidebar-block">
                                        <a className="cabinet-sidebar-item" href={'#' + SpaRoutes.SUPPLIER_PROFILE}>
                                            <img
                                                width={24}
                                                src="/Pictures/cabinet-user.svg"
                                            />
                                            <p>Учетные данные</p>
                                            <img
                                                width={16}
                                                src="/Pictures/cabinet-chevron-right.svg"
                                            />
                                        </a>
                                    </div>

                                    <hr />

                                    {BurgerButtons}

                                    <hr />

                                    <div className="cabinet-sidebar-block-helps">
                                        <p>Тех. поддержка</p>
                                        <a href="#">{store.helpNumber}</a>
                                        <a href="#/logout">Выйти</a>
                                    </div>
                                </div>
                            </div>

                            <div className="cabinet-right">
                                <div>
                                    <p>Самовывоз</p>
                                </div>

                                <div className="cabinet-provider-card">
                                    <Link to={SpaRoutes.Seller.Orders.PickUp.Open}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-flag.svg"
                                            />
                                            <p>Новые заказы</p>
                                            <span>{CabinetData?.pickUpOpenOrders}</span>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.Seller.Orders.PickUp.Accepted}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-file-success.svg"
                                            />
                                            <p>Взято в работу</p>
                                            <span>{CabinetData?.pickUpAcceptedOrders}</span>
                                        </div>
                                    </Link>

                                    <Link to={SpaRoutes.Seller.Orders.PickUp.ReadyToGive}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-box.svg"
                                            />
                                            <p>Готов к выдаче</p>
                                            <span>{CabinetData?.pickUpReadyToGiveOrders}</span>
                                        </div>
                                    </Link>

                                    <Link to={SpaRoutes.Seller.Orders.PickUp.Received}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-watch.svg"
                                            />
                                            <p>Выданы</p>
                                            <span>{CabinetData?.pickUpReceivedOrders}</span>
                                        </div>
                                    </Link>
                                </div>

                                <div>
                                    <p>Доставка</p>
                                </div>

                                <div className="cabinet-provider-card">
                                    <Link to={SpaRoutes.Seller.Orders.Delivery.Open}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-flag.svg"
                                            />
                                            <p>Новые заказы</p>
                                            <span>{CabinetData?.deliveryOpenOrders}</span>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.Seller.Orders.Delivery.Accepted}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-file-success.svg"
                                            />
                                            <p>Взято в работу</p>
                                            <span>{CabinetData?.deliveryAcceptedOrders}</span>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.Seller.Orders.Delivery.ReadyToDelivery}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-box.svg"
                                            />
                                            <p>Готов к отправке</p>
                                            <span>{CabinetData?.deliveryReadyToDeliveryOrders}</span>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.Seller.Orders.Delivery.Sending}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-watch.svg"
                                            />
                                            <p>В доставке</p>
                                            <span>{CabinetData?.deliverySendingOrders}</span>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.Seller.Orders.Delivery.Received}>
                                        <div className="cabinet-provider-card-item">
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-watch.svg"
                                            />
                                            <p>Выданы</p>
                                            <span>{CabinetData?.deliveryReceivedOrders}</span>
                                        </div>
                                    </Link>
                                </div>

                                <Link to={SpaRoutes.Seller.Orders.Closed}>
                                    <div className="cabinet-provider-order">
                                        <div className="cabinet-provider-order-info">
                                            <p>Выполнены</p>
                                            <span>{CabinetData?.closedOrders} заказа</span>
                                        </div>
                                        <div>
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-order-success.svg"
                                            />
                                        </div>
                                    </div>
                                </Link>

                                <div className="cabinet-provider-card">
                                    <Link to={SpaRoutes.Seller.Orders.CanceledBuyer}>
                                        <div className="cabinet-provider-card">
                                            <div className="cabinet-provider-card-item">
                                                <img
                                                    width={48}
                                                    src="/Pictures/cabinet-circle-cancel.svg"
                                                />
                                                <p>Ожидают отмены</p>
                                                <span>{CabinetData?.canceledBuyerOrders}</span>
                                            </div>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.Seller.Orders.Canceled}>
                                        <div className="cabinet-provider-card">
                                            <div className="cabinet-provider-card-item">
                                                <img
                                                    width={48}
                                                    src="/Pictures/cabinet-circle-cancel.svg"
                                                />
                                                <p>Отмененные</p>
                                                <span>{CabinetData?.canceledOrders}</span>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div>
                                    <p>Платежная информация</p>
                                </div>

                                <div className="cabinet-provider-card cabinet-provider-money-card">
                                    <Link to={SpaRoutes.SUP_CATALOG}>
                                        <div className="cabinet-provider-card-item">
                                            <span>Активные карточки</span>
                                            <p>{CabinetData?.countActiveCards}</p>
                                        </div>
                                    </Link>
                                    <Link to={SpaRoutes.SUP_CARDS_PAYMENT_HISTORY}>
                                        <div className="cabinet-provider-card-item">
                                            <span>Оплата за размещение</span>
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-market.svg"
                                            />
                                        </div>
                                    </Link>
                                    <Link to={"/bonuses"}>
                                        <div className="cabinet-provider-card-item">
                                            <span>Баланс</span>
                                            <p data-size={(CabinetData?.bonusPoints.toFixed(2).toString().length ? CabinetData?.bonusPoints.toFixed(2).toString().length : 0) > 5 && "large"}>{CabinetData?.bonusPoints.toFixed(2)} Б</p>
                                            <img
                                                width={48}
                                                src="/Pictures/cabinet-circle-pocket.svg"
                                            />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}
export default observer(SellerHome);
