import React, {useContext, useState} from 'react';
import styles from "./ChangeFirstPriceBonusesPopup.module.scss"
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import CustomInput from "../../../../customUI/CustomInput/CustomInput";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import {Context} from "../../../../../index";

interface Props {
    type: "price" | "bonuses",
    isVirtual?: boolean,
    selectedRegion?: string,
    selectedMagazine?: any,
    onClose: () => void,
    onUpdate: () => void
}

const ChangeFirstPriceBonusesPopup = ({type, isVirtual, selectedRegion, selectedMagazine, onClose, onUpdate}: Props) => {
    const {store} = useContext((Context))

    let selectFilterText = "Для всех товаров"

    if(selectedRegion && !selectedMagazine) {
        selectFilterText = `Для всех товаров в регионе - ${isVirtual?"(Вирт.)":""} ${selectedRegion}`
    }
    else if(selectedMagazine) {
        selectFilterText = `Для всех товаров на складе - ${selectedMagazine.city}, ${selectedMagazine.streetAndNumber} (ID: ${selectedMagazine?.dropAdressId})`
    }

    const [value, setValue] = useState(0);

    const update = () => {
        let data = {
            Info1: type,
            Number1: value,
            Bool: isVirtual,
            Info2: selectedRegion,
            Info3: selectedMagazine?.dropAdressId
        }
        store.DataLoadingON()
        CabinetSellerService.changeFirstPriceBonuses(data)
            .then(() => {
                onUpdate()
                onClose()
            })
            .catch(() => store.showDialog("Что-то пошло не так"))
            .finally(() => store.DataLoadingOFF())
    }

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <CustomNavigateHeader title={type == "price"? "Изменить первичную цену" : "Изменить первичные бонусы"} type={"close"} onClick={() => onClose()} />

                <div className={styles.selectFilter}>
                    {selectFilterText}
                </div>

                {type === "price" ?
                    <div className={styles.inputContainer}>
                        Цена %
                        <CustomInput type={"number"} inputSize={"small"} value={value} onChange={(event) => setValue(event.target.value)} min={"0"} max={"100"} />
                    </div>
                    :
                    <div className={styles.inputContainer}>
                        Бонусы %
                        <CustomInput type={"number"} inputSize={"small"} value={value} onChange={(event) => setValue(event.target.value)} min={"0"} max={"100"} />
                    </div>
                }

                <div className={styles.button} onClick={() => update()}>
                    Изменить
                </div>

            </div>
        </div>
    );
};

export default ChangeFirstPriceBonusesPopup;