import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import "./Bonuses.css"
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IBaseInformation, IBonusTransaction} from "../../../../models/models";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";
import CustomInput from "../../../customUI/CustomInput/CustomInput";
import {Context} from "../../../../index";
import HomeService from "../../../../services/HomeService";
import BonusTransaction from "./BonusTransaction";
import BonusesDebtsTable from "./BonusesDebtsTable";
import PricesTable from "./PricesTable/PricesTable";

const requisitesText =
    "Полное наименование: \n" +
    "Общество с ограниченной ответственностью «Звезда» \n" +
    "\n" +
    "Краткое наименование ООО «Звезда» \n" +
    "Генеральный директор Иванов Вадим Юрьевич\n" +
    "\n" +
    "Юридический адрес\n" +
    " 124533, Россия, Рязанская область, г. Рязань, ул. Ленина, 52, корп. 1, \n" +
    "оф. 5 \n" +
    "\n" +
    "Почтовый адрес\n" +
    " 124533, Россия, Рязанская область, г. Рязань, ул. Ленина, 52, корп. 1, оф. 5\n" +
    " \n" +
    "Телефон/e-mail:     +7 (4912) 21-66-99 Zvezdaooo@mail.ru \n" +
    "ИНН 0278000222 \n" +
    "КПП 027811001 \n" +
    "ОГРН 1030224552966 \n" +
    "ОКВЭД 22.21 \n" +
    "ОКПО 02428896 \n" +
    "ОКАТО 61401000000 \n" +
    "Расчетный счет 40602810206000050025 \n" +
    "\n" +
    "Полное наименование банка \n" +
    "ОАО «Уралторгбанк России», г. Рязань.\n" +
    " \n" +
    "БИК      046526969 \n" +
    "Корреспондентский счет банка      30101810600002500957\n"

const Bonuses = () => {
    const {store} = useContext(Context)
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const [bonusBalance, setBonusBalance] = useState<{ balance: number, freeBonuses: number }>({
        balance: 0,
        freeBonuses: 0
    })
    const [dataBonusTransactions, setDataBonusTransactions] = useState<IBonusTransaction[]>()
    const [transactions, setTransactions] = useState<IBonusTransaction[]>()
    const [isLastNegative, setIsLastNegative] = useState(false)
    const [isLastPositive, setIsLastPositive] = useState(false)

    const [baseInfo, setBaseInfo] = useState<IBaseInformation>()
    const [requisitesButtonText, setRequisitesButtonText] = useState("Скопировать реквизиты")
    const [debts, setDebts] = useState<any[]>([])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    useEffect(() => {
        store.DataLoadingON()
        CabinetSellerService.getBonusBalance()
            .then(response => {
                setBonusBalance(response.data)

                HomeService.getBaseInformation()
                    .then((response) => setBaseInfo(response.data))
                    .catch(error => store.ErrorON())

                CabinetSellerService.getSellerBonusesDebts()
                    .then((r) => {
                        setDebts(r.data)
                    })

                CabinetSellerService.getBonusTransactions({"Number1": "6"})
                    .then(response => {
                        setDataBonusTransactions(response.data)
                    })
                    .catch(error => store.ErrorON())

                    .then(() => {
                        setLoading(true)
                    })
                    .catch(error => store.ErrorON())
            })
            .catch(error => store.ErrorON())
            .finally(() => store.DataLoadingOFF())
    }, [])

    const parseDateFromString = (input: any) => {
        input = input.split(" ")
        let date = new Date;
        date.setDate(parseInt(input[0].slice(0, 2)));
        date.setMonth(parseInt(input[0].slice(3, 5)) - 1);
        date.setFullYear(parseInt(input[0].slice(6, 10)));
        date.setHours(parseInt(input[1].slice(0, 2)));
        date.setMinutes(parseInt(input[1].slice(3, 5)));
        date.setSeconds(parseInt(input[1].slice(6, 10)));
        return date;
    }

    const getLast = () => {
        if (dataBonusTransactions) {
            const arr = dataBonusTransactions.slice()
            setTransactions(arr
                .sort(function (a, b): any {
                    var dateA = new Date(parseDateFromString(a.date)).getTime()
                    var dateB = new Date(parseDateFromString(b.date)).getTime()
                    return dateB - dateA
                }).slice(0, 6)
            )
        }
    }

    useEffect(() => {
        if (isLastNegative) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value < 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else if (isLastPositive) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value > 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else {
            getLast()
        }
    }, [isLastNegative, isLastPositive])

    useEffect(() => {
        getLast()
    }, [loading])

    const divRef = useRef(null);

    const requisitesButtonClick = () => {
        // @ts-ignore
        const text = divRef.current.map().textContent;
        navigator.clipboard.writeText(text)
        setRequisitesButtonText("Реквизиты скопированы")
        setTimeout(function () {
            setRequisitesButtonText("Скопировать реквизиты")
        }, 5000);
    }

    function createMarkup(a: any) {
        if (a)
            return {__html: a};
        else
            return {__html: ""}
    }

    return (
        <div className={"bonuses-mainContainer"}>
            <div className={"bonuses-navigate-container"}>
                <img className={"bonuses-navigate-image"} onClick={() => navigate(-1)}
                     src={"/Pictures/strelochka.svg"}/>
                <div className={"bonuses-navigate-title"}>Баланс бонусов</div>
            </div>

            <div className={"bonuses-firstContainer"}>
                <div className={"bonuses-quantity-rules-container"}>
                    <div className={"bonuses-quantityCard"}>
                        <div className={"bonuses-quantityCard-quantity"}>
                            {bonusBalance.balance.toFixed(2)}
                        </div>
                        <div className={"bonuses-quantityCard-text"}>бонусов</div>

                        <div className={"bonuses-quantityCard-freeBonuses"}>
                            {bonusBalance.freeBonuses.toFixed(2)}
                        </div>
                        <div className={"bonuses-quantityCard-text"}>бесплатных</div>
                    </div>
                    <div className={"bonuses-rulesDeposit-title"}>Условия пополнения</div>
                    <div className={"bonuses-rulesDeposit"}
                         dangerouslySetInnerHTML={createMarkup(baseInfo?.cardPricingText)}
                    >

                    </div>
                </div>

                <div className={"bonuses-historyOperations-container"}>
                    <div className={"bonuses-historyOperations-header"}>
                        История операций:
                        <div className={"bonuses-historyOperations-buttons"}>
                            <button className={"bonuses-historyOperations-button"}
                                    data-isActive={isLastNegative}
                                    onClick={() => {
                                        setIsLastNegative(!isLastNegative)
                                        setIsLastPositive(false)
                                    }
                                    }>Расходы
                                {isLastNegative && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                            </button>
                            <button className={"bonuses-historyOperations-button"}
                                    data-isActive={isLastPositive}
                                    onClick={() => {
                                        setIsLastPositive(!isLastPositive)
                                        setIsLastNegative(false)
                                    }
                                    }>Зачисления
                                {isLastPositive && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                            </button>
                        </div>
                    </div>

                    <div className={"bonuses-historyOperations-operations"}>
                        <BonusTransaction transactions={transactions}/>
                    </div>
                    {transactions?.length != 0 &&
                        <div className={"bonuses-historyOperations-watchAll"}>
                            <Link to={"/bonusesTransactionHistory"}>
                                Смотреть все
                            </Link>
                        </div>
                    }
                </div>
            </div>

            {debts.length > 0 &&
                <BonusesDebtsTable debts={debts}/>
            }

            <PricesTable/>

            <div className={"bonuses-secondContainer"}
                 style={{marginTop: transactions?.length === 0 ? "100px" : ""}}>
                Пополнение баланса
                <div className={"bonuses-manual-requisites"}>
                    <div className={"bonuses-manual"}>
                        <div className={"bonuses-manual-header"}>Инструкция:</div>
                        <div className={"bonuses-manual-text"}>
                            Для пополнения баланса, переведите средства по реквизитам справа и свяжитесь с
                            администраторы. Средства поступят в течении нескольких часов.
                        </div>
                    </div>
                    <div className={"bonuses-requisites-container"}>
                        <div className={"bonuses-requisites"}>
                            <div className={"bonuses-requisites-header"}>Реквизиты</div>
                            <div className={"bonuses-requisites-text"} ref={divRef}
                                 dangerouslySetInnerHTML={createMarkup(baseInfo?.requisitiText)}
                            >
                            </div>
                        </div>
                        {/*<button className={"bonuses-requisites-button"}*/}
                        {/*        onClick={() => requisitesButtonClick()}*/}
                        {/*>{requisitesButtonText}</button>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Bonuses;