import React, {useContext, useEffect, useState} from 'react';
import styles from "./PricesTable.module.scss"
import CustomAntTable from "../../../../customUI/CustomAntTable/CustomAntTable";
import {SpaRoutes} from "../../../../../Routes/spaRoutes";
import {Context} from "../../../../../index";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import CustomInput from "../../../../customUI/CustomInput/CustomInput";

interface ITableData {
    id: string,
    count: number,
    price: number
}

const PricesTable = () => {
    const {store} = useContext(Context);

    const [tableData, setTableData] = useState<ITableData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const [cardCount, setCardCount] = useState<number>(0);
    const [calculatedCostPerOne, setCalculatedCostPerOne] = useState<number>(0);
    const [calculatedCost, setCalculatedCost] = useState<number>(0);

    const getTableData = () => {
        setLoading(true);
        CabinetSellerService.getPricesTable()
            .then((r) => {
                setTableData(r.data.sort((a: ITableData, b: ITableData) => a.count - b.count));
            })
            .catch()
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getTableData();
    }, []);

    const truncateToTwo = (num: number) => (Math.floor(num * 100) / 100);

    const calculateTotalPrice = (cardCount: number, tableData: ITableData[]) => {
        if (tableData.length === 0 || cardCount <= 0) {
            setCalculatedCostPerOne(0)
            setCalculatedCost(0);
            return;
        }

        tableData.sort((a, b) => a.count - b.count);

        if (cardCount <= tableData[0].count) {
            setCalculatedCostPerOne(tableData[0].price)
            setCalculatedCost(tableData[0].price * cardCount);
            return;
        }

        if (cardCount >= tableData[tableData.length - 1].count) {
            setCalculatedCostPerOne(tableData[tableData.length - 1].price)
            setCalculatedCost(tableData[tableData.length - 1].price * cardCount);
            return;
        }

        for (let i = 0; i < tableData.length - 1; i++) {
            const lowerBound = tableData[i];
            const upperBound = tableData[i + 1];

            if (cardCount >= lowerBound.count && cardCount <= upperBound.count) {
                const pricePerOne =
                    lowerBound.price +
                    ((upperBound.price - lowerBound.price) / (upperBound.count - lowerBound.count)) *
                    (cardCount - lowerBound.count);

                setCalculatedCostPerOne(truncateToTwo(pricePerOne))
                setCalculatedCost(truncateToTwo(pricePerOne) * cardCount);
                return;
            }
        }

        setCalculatedCostPerOne(0)
        setCalculatedCost(0);
    };

    const calculateCostsButton = () => {
        calculateTotalPrice(cardCount, tableData);
    };

    const columns = [
        {
            title: "№",
            dataIndex: "id",
            render: (_: any, record: any, index: number) => index + 1,
            width: 30
        },
        {
            title: "Кол-во",
            dataIndex: "count",
            width: 150
        },
        {
            title: "Цена за ед.",
            dataIndex: "price",
            render: (price: number) => `${price.toFixed(2)} ₽`,
            width: 150
        },
        {
            title: "Цена за 1 д.",
            dataIndex: "pricePerDay",
            render: (_: any, record: ITableData) => `${(record.price * record.count).toFixed(2)} ₽`,
            width: 150
        },
        {
            title: "Цена за 30 д.",
            dataIndex: "pricePerMonth",
            render: (_: any, record: ITableData) => `${(record.price * record.count * 30).toFixed(2)} ₽`,
            width: 150
        }
    ];

    return (
        <div className={styles.container}>
            <div className={"bonuses-secondContainer"}>
                <div className={"bonus_calc_header"}>
                    Калькулятор
                </div>
                <div className={"bonus_calc_text"}>
                    Сколько будет активных карточек:
                </div>
                <div className={"bonus_calc_input_box"}>
                    <div className={"bonus_calc_input"}>
                        <CustomInput
                            name={"cardCount"}
                            type={"number"}
                            value={Number(cardCount)}
                            icon={false}
                            onChange={(event) => {
                                setCardCount(Number(event.target.value));
                            }}
                            inputSize={"large"}
                        />
                    </div>
                    <div className={"bonus_calc_button"}>
                        <button className={"bonuses_calculator_button"}
                                onClick={calculateCostsButton}
                        >
                            Рассчитать
                        </button>
                    </div>
                </div>

                <div className={"bonus_calc_answer1"}>
                    Итоговая стоимость: {calculatedCost.toFixed(2)} баллов
                    ({(calculatedCostPerOne.toFixed(2))} баллов за карточку)
                </div>
            </div>

            <CustomAntTable
                data={tableData}
                columns={columns}
                loading={loading}
                scroll={{x: "max-content"}}
            />
        </div>
    );
};

export default PricesTable;
