import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import "./Bonuses.css"
import CabinetSellerService from "../../../../services/CabinetSellerService";
import {IBonusTransaction} from "../../../../models/models";
import {BeatLoader} from "react-spinners";
import {Context} from "../../../../index";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";

const BonusesTransactionHistory = () => {
    const {store} = useContext(Context)

    const navigate = useNavigate();
    const [dataBonusTransactions, setDataBonusTransactions] = useState<IBonusTransaction[]>()
    const [transactions, setTransactions] = useState<IBonusTransaction[]>()

    const [loadedBonusTransaction, setLoadedBonusTransaction] = useState(false)
    const [isLastNegative, setIsLastNegative] = useState(false)
    const [isLastPositive, setIsLastPositive] = useState(false)

    useEffect(() => {
        if (!loadedBonusTransaction) {
            if (store.roles.includes("Dealer")) {
                store.DataLoadingON()
                CabinetSellerService.getBonusTransactions({"Number1": "-1"})
                    .then(response => {
                        if (response.data) {
                            setDataBonusTransactions(response.data)

                            setLoadedBonusTransaction(true)
                        }
                    })
                    .catch(response => {
                        store.ErrorON()
                    })
                    .finally(() => store.DataLoadingOFF())
            } else if (store.roles.includes("Customer")) {
                store.DataLoadingON()
                CabinetBuyerService.getBonusTransactions(-1)
                    .then(response => {
                        if (response.data) {
                            setDataBonusTransactions(response.data)
                            setLoadedBonusTransaction(true)
                        }
                    })
                    .catch(response => {
                        store.ErrorON()
                    })
                    .finally(() => store.DataLoadingOFF())
            }

        }
    }, [])


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const parseDateFromString = (input: any) => {
        input = input.split(" ")
        let date = new Date;
        date.setDate(parseInt(input[0].slice(0, 2)));
        date.setMonth(parseInt(input[0].slice(3, 5)) - 1);
        date.setFullYear(parseInt(input[0].slice(6, 10)));
        date.setHours(parseInt(input[1].slice(0, 2)));
        date.setMinutes(parseInt(input[1].slice(3, 5)));
        date.setSeconds(parseInt(input[1].slice(6, 10)));
        return date;
    }

    const getLast = () => {
        if (dataBonusTransactions) {
            const arr = dataBonusTransactions.slice()
            setTransactions(arr
                .sort(function (a, b): any {
                    var dateA = new Date(parseDateFromString(a.date)).getTime()
                    var dateB = new Date(parseDateFromString(b.date)).getTime()
                    return dateB - dateA
                })
            )
        }
    }

    useEffect(() => {
        if (isLastNegative) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value < 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else if (isLastPositive) {
            if (dataBonusTransactions) {
                const arr: IBonusTransaction[] = dataBonusTransactions.slice()
                setTransactions(arr
                    .filter((item) => item.value > 0)
                    .sort(function (a, b): any {
                        var dateA = new Date(parseDateFromString(a.date)).getTime()
                        var dateB = new Date(parseDateFromString(b.date)).getTime()
                        return dateB - dateA
                    })
                )
            }
        } else {
            getLast()
        }
    }, [isLastNegative, isLastPositive])

    useEffect(() => {
        getLast()
    }, [loadedBonusTransaction])

    const BonusTransaction = () => {
        if (transactions?.length != 0 && transactions) {
            return <>{transactions.map((item) => {
                return (
                    <div className={"bonuses-historyOperations-operation"}>
                        <div className={"bonuses-historyOperations-operation-date"}>{item.date}</div>
                        <div className={"bonuses-historyOperations-operation-name-bonus"}>
                            <div className={"bonuses-historyOperations-operation-name"}>{item.info}</div>
                            <div
                                className={"bonuses-historyOperations-operation-bonus"}>{item.value > 0 ? "+" : ""}{item.value.toFixed(2)} бон.
                            </div>
                        </div>
                    </div>
                )
            })
            }</>
        } else {
            return <>
                <div className={"bonuses-historyOperations-operation"}>
                    <div className={"bonuses-historyOperations-operation-noOperation"}>
                        Нет операций
                    </div>
                </div>
            </>
        }
    }

    return (
        <div className={"bonuses-mainContainer"}>
            <div className={"bonuses-navigate-container"}>
                <img className={"bonuses-navigate-image"} onClick={() => navigate(-1)}
                     src={"/Pictures/strelochka.svg"}/>
                <div className={"bonuses-navigate-title"}>История операций</div>
            </div>


            <div className={"bonuses-historyOperations-header"}>

                <div className={"bonuses-historyOperations-buttons"}>
                    <button className={"bonuses-historyOperations-button"}
                            data-isActive={isLastNegative}
                            onClick={() => {
                                setIsLastNegative(!isLastNegative)
                                setIsLastPositive(false)
                            }
                            }>Расходы
                        {isLastNegative && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                    </button>
                    <button className={"bonuses-historyOperations-button"}
                            data-isActive={isLastPositive}
                            onClick={() => {
                                setIsLastPositive(!isLastPositive)
                                setIsLastNegative(false)
                            }
                            }>Зачисления
                        {isLastPositive && <img src={"/Pictures/whiteDownArrow.svg"}/>}
                    </button>
                </div>
            </div>

            <div className={"bonuses-historyOperations-operations"}>
                <BonusTransaction/>
            </div>
        </div>
    );
};

export default BonusesTransactionHistory;