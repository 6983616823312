import React, {useContext, useEffect, useState} from "react";

import CustomTable, {IRowElement, ITable} from "../../../customUI/CustomTable/CustomTable";
import "./SupplierCardsHistory.css"
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import {Context} from "../../../../index";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import {Transition} from "react-transition-group";
import {calculateDateDifference, getParsedDate, removeDuplicates2} from "../../../../functions/functions";
import {ToogleVisible} from "../../../customUI/Searchbar/functions";
import {Pagination} from "antd";


interface FirstResponseFor116 {
    totalBonuses: number;
    listOfDates: ListOfDates[];
}

interface ListOfDates {
    totalPricePerOne: number;
    totalPriceFree: any;
    totalPrice: any;
    totalAmount: number;
    date: string;
    listOfSupplierDropPoints: ListOfSupplierDropPoints[];
}

interface ListOfSupplierDropPoints {
    adressOfDropPoint: string;
    dropPointId: string;
    totalMoney: number;
    totalMoneyFree: number;
    amount: number;
}


interface TableWithShowModes {
    showMode: boolean[];
    main: ITable;
}

interface ListOfCategories2 {
    topCategoryName: string;
    topCategoryId: string;
}

interface ListOfProducts2 {
    productId: string;
    title: string;
    price: string;
    amount: string;
    discountPrice: string;
    wasCheap: boolean;
    isActivated: boolean;
    lastActive: string;
    lastDeactive: string;
    bonusPoints: string;
    magazineName: string;
    magazineId: string;
    subCategoryId: string;
    subCategoryName: string;
    topCategoryId: string;
    topCategoryName: string;
}

interface ParsedDataFor2Table {
    topCategoryId: string;
    topCategoryName: string;
    listOfProducts: ListOfProducts2[];
}


export default function SupplierCardsHistory() {

    const {store} = useContext(Context);

    //стейт для выбора таблицы (если true, то 2 страница, если false, то первая)
    const [showInfo, setShowInfo] = useState(false);
    //Вспомогательный стейт
    const [nextStep, setNextStep] = useState(false);
    //стейт для хранения значения открытия для каждой строки
    const [showModeForEachRow, setShowModeForEachRow] = useState<boolean[]>([]);
    //стейт для хранения среднего значения цены за карточки за конкретную дату
    const [totalSpentMoney, setTotalSpentMoney] = useState<number[]>([]);
    //стейт для хранения просуммированного количества карточек со всех складов
    const [totalAmount, setTotalAmount] = useState<number[]>([]);
    //стейт для хранения количества бонусов
    const [bonusesLeft, setBonusesLeft] = useState<number>();
    //стейт для хранения данных первой таблицы
    const [FirstTable, setFirstTable] = useState<TableWithShowModes>({
        showMode: [],
        main: {
            tableId: "InfoTable",
            header: [],
            rows: [],
        }
    });

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const [remainingDays, setRemainingDays] = useState<number>(0);
    const [dataForSupplier, setDataForSupplier] = useState<FirstResponseFor116>();

    const [dataForSecondTable, setDataForSecondTable] = useState<ListOfProducts2[]>([]);
    const [parsedDataForSecondTable, setParsedDataForSecondTable] = useState<ParsedDataFor2Table[]>([]);
    const [listCategoriesForSecondTable, setListCategoriesForSecondTable] = useState<ListOfCategories2[]>([]);

    const [dateInfo, setDateInfo] = useState<any>(<></>);
    //стейт для хранения данных второй таблицы (для совокупности таблиц)
    const [listOfTables, setListOfTables] = useState<ITable[]>([]);

    const [pageTable, setPageTable] = useState(1)
    const [totalTable, setTotalTable] = useState(0)


    function sortListOfDates(data: FirstResponseFor116): FirstResponseFor116 {
        const sortedListOfDates = data.listOfDates.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB.getTime() - dateA.getTime(); // Сортируем по возрастанию даты
        });

        return {...data, listOfDates: sortedListOfDates};
    }

    //функция для переворота даты
    function convertDateFormat(date: string) {
        const parts = date.split('.'); // Разделяем строку на части по точке
        const day = parts[2]; // День
        const month = parts[1]; // Месяц
        const year = parts[0]; // Год
        return `${day}.${month}.${year}`; // Создаем новую строку с переставленными частями
    }

    //хендлер для открытия второй таблицы для поставщика
    const HandlerForOpenInfo = async (date: string, dropPointAddress: string, dropPointId: string) => {
        store.DataLoadingON();
        setDateInfo(
            <div className={"supplier_scph_second_page_description"}>
                <div>Дата:
                    <div className="supplier_scph_second_page_description_date">
                        {convertDateFormat(date)}
                    </div>
                </div>
                <div>Адрес склада:
                    <div className="supplier_scph_second_page_description_date">
                        {dropPointAddress}
                    </div>
                </div>
            </div>)
        await CabinetSellerService.postDailyPayInfoSingle(date, dropPointId).then((response) => {
            if (response.data.length > 0) {
                let categories: ListOfCategories2[] = [];
                setDataForSecondTable(response.data);
                for (const product of response.data) {
                    categories.push({
                        topCategoryName: product.topCategoryName,
                        topCategoryId: product.topCategoryId,
                    })
                }
                categories = removeDuplicates2(categories);
                setListCategoriesForSecondTable(categories);
            }

        })
            .catch(() => {
                store.ErrorON();
            })
            .finally(() => {
                store.DataLoadingOFF();
                setShowInfo(true)
            })
    }

    //хендлер для закрытия второй таблицы
    const HandlerForCloseInfo = () => {
        setShowInfo(false);
    }

    const changeHandler = (text: string) => {
        setListOfTables((prevState) => {
                let newState = prevState.map((ele => {
                    ele.rows = ToogleVisible(text, ["Title", "ID"], ["", ""], ele.rows)
                    return ele
                }))
                return newState;
            }
        )
    }

    const SplitTables = () => {
        return listOfTables.map((tbox, i) => {
            if (tbox.rows.map((ele) => ele.visible.every((item) => item === true)).every((item) => item === false)) {
                return <></>
            }
            return <div
                className={`supplier_scph_table_container_2 ${i === 0 ? ("supplier_scph_table_container_first") : ("")}`}>
                <div
                    className={"bdproTab_subHeader supplier_scph_table_header"}>{listCategoriesForSecondTable[i].topCategoryName}</div>
                <CustomTable {...tbox}/>
            </div>
        });
    }

    useEffect(() => {
        let result: ParsedDataFor2Table[] = [];
        let list: ListOfProducts2[] = [];

        if (listCategoriesForSecondTable && listCategoriesForSecondTable.length > 0) {
            for (const category of listCategoriesForSecondTable) {
                for (const product of dataForSecondTable) {
                    if (product.topCategoryId === category.topCategoryId) {
                        list.push(product);
                    }
                }
                result.push({
                    topCategoryId: category.topCategoryId,
                    topCategoryName: category.topCategoryName,
                    listOfProducts: list,
                })
                list = [];
            }
        }

        setParsedDataForSecondTable(result);
    }, [listCategoriesForSecondTable])

    useEffect(() => {
        let Tables: ITable[] = [];
        let list: IRowElement[] = [];

        for (let i = 0; i < parsedDataForSecondTable.length; i++) {
            for (const product of parsedDataForSecondTable[i].listOfProducts) {
                let isCheapText: string = `${(100.0 - Number(product.discountPrice) * 100.0 / Number(product.price)).toFixed()} %`;
                list.push({
                    content: {
                        Title:
                            <div data-search-word={product.title} className={"TableLongInfo"}>
                                <div className={"TableLongText"}>{product.title ? product.title : "-"}</div>
                            </div>,
                        // ID:
                        //     <div data-search-word={product.productId}>
                        //         <div>{product.productId?product.productId:"-"}</div>
                        //     </div>,
                        Active: product.isActivated ?
                            <div>
                                <img alt={"ок"} src={"/Pictures/ok.svg"}/>
                            </div>
                            : <div>
                                <img alt={"not ok"} src={"/Pictures/notok.svg"}/>
                            </div>,
                        TimeActive:
                            <div>
                                {calculateDateDifference(product.lastDeactive, product.lastActive)}
                            </div>,
                        Discount: product.wasCheap ?
                            <div className={"bdproTab_disField"}>{isCheapText}</div>
                            : <div className={"bdproTab_disField"}>
                                <img alt={"not ok"} className={""} src={"/Pictures/notok.svg"}/>
                            </div>,
                    },
                    filter: [],
                    visible: [true],
                })
            }
            Tables.push({
                tableId: "ProductTable" + parsedDataForSecondTable[i].topCategoryId,
                header: ["Продукт", "Активность", "Был активен", "Скидка"],
                rows: list,
            })
            list = [];

        }

        setListOfTables(Tables);
    }, [parsedDataForSecondTable])


    // ----------- Начало растасовки данных для поставщика ----------------------------------------- //

    //хендлер для открытия и закрытия свернутых строк для поставщика
    const SupplierHandlerForUnderRows = (date: string, index: number) => {
        setFirstTable((prevState: TableWithShowModes) => {
            let newState = {...prevState};
            let foundDate: boolean = false;

            newState.showMode[index] = !newState.showMode[index];

            newState.main.rows = newState.main.rows.map(obj => {
                if (Object.hasOwn(obj.content, 'date')) {
                    if (obj.content.date.props.children[0] === date) {
                        foundDate = true;
                        return obj;
                    } else foundDate = false;
                } else if (foundDate && obj.visible[0] !== newState.showMode[index]) {
                    obj.visible[0] = newState.showMode[index];
                }
                return obj;
            });
            foundDate = false;

            return newState;
        });
    }

    //парсинг данных с контроллера (растасовка для поставщика)
    function ParseDataForOneSupplier(data: FirstResponseFor116): IRowElement[] {
        let list: IRowElement[] = [];
        let ShowModeList: boolean[] = [];
        let totalNumberOfCards: number[] = [];
        let numberOfCardsPerDate: number = 0;
        let spentMoneyEveryDay: number[] = [];
        let sumSpentPerDay: number = 0;

        const AverageCost = (amount: number, totalPrice: number): number => {
            return totalPrice / amount;
        }

        setBonusesLeft(data.totalBonuses);

        for (let i = 0; i < data.listOfDates.length; i++) {
            ShowModeList.push(false);
            let ReversedParsedDate = getParsedDate(data.listOfDates[i].date, false, false, true)
            list.push(
                {
                    content: {
                        date:
                            <div style={{position: "relative"}}>
                                {data.listOfDates[i].date ? getParsedDate(data.listOfDates[i].date, true, false) : "0000.00.00"}
                                <div
                                    style={{position: "relative"}}
                                    onClick={() => {
                                        SupplierHandlerForUnderRows(getParsedDate(data.listOfDates[i].date, true, false), i)
                                    }}
                                >
                                    <input
                                        id={data.listOfDates[i].date}
                                        type="checkbox"
                                        className={"supplier_scph_drop_img_checkbox"}
                                    />
                                    <img
                                        alt={"open"}
                                        src={"/Pictures/dropDownIcon.svg"}
                                        className={"supplier_scph_drop_img"}>
                                    </img>
                                </div>
                            </div>,
                        amount: <div>{data.listOfDates[i].totalAmount ? data.listOfDates[i].totalAmount : 0}</div>,
                        price:
                            <div>
                                {
                                    data.listOfDates[i].totalAmount > 0 ?
                                        `${(data.listOfDates[i].totalPrice + data.listOfDates[i].totalPriceFree) / data.listOfDates[i].totalAmount} Б`
                                        : "0 Б"
                                }
                            </div>,
                        total:
                            <div>
                                {
                                    data.listOfDates[i].totalPrice > 0 ?
                                        `${( data.listOfDates[i].totalPrice).toFixed(2)} Б` : "0 Б"
                                }
                                {
                                    data.listOfDates[i].totalPriceFree > 0 &&
                                    `, ${(data.listOfDates[i].totalPriceFree).toFixed(2)} бесп. Б`
                                }
                            </div>,

                    },
                    filter: [],
                    visible: [true]
                }
            )
            if (Object.hasOwn(data.listOfDates[i], 'listOfSupplierDropPoints')) {
                let counter: number = 0;
                for (const DropPoint of data.listOfDates[i].listOfSupplierDropPoints) {
                    counter++;
                    list.push(
                        {
                            content: {
                                placeholder: <div style={{cursor: "pointer"}} className={"TableLongInfo"}
                                                  onClick={DropPoint.amount > 0 ? (() => {
                                                      HandlerForOpenInfo(ReversedParsedDate, DropPoint.adressOfDropPoint, DropPoint.dropPointId)
                                                  }) : (() => {
                                                  })}>
                                    <div
                                        className={"TableLongText"}>{DropPoint.adressOfDropPoint ? DropPoint.adressOfDropPoint : "-"}</div>
                                </div>,
                                amount:
                                    <div style={{cursor: "pointer"}}
                                         onClick={DropPoint.amount > 0 ? (() => {
                                             HandlerForOpenInfo(ReversedParsedDate, DropPoint.adressOfDropPoint, DropPoint.dropPointId)
                                         }) : (() => {
                                         })}
                                    >
                                        {DropPoint.amount ? DropPoint.amount : 0}
                                    </div>,
                                price:
                                    <div style={{cursor: "pointer"}} onClick={DropPoint.amount > 0 ? (() => {
                                        HandlerForOpenInfo(ReversedParsedDate, DropPoint.adressOfDropPoint, DropPoint.dropPointId)
                                    }) : (() => {
                                    })}>
                                        {
                                            DropPoint.amount > 0 ?
                                                `${((DropPoint.totalMoney + DropPoint.totalMoneyFree) / DropPoint.amount)} Б`
                                                : "0 Б"

                                        }
                                    </div>,
                                total:
                                    <div style={{cursor: "pointer"}} onClick={DropPoint.amount > 0 ? (() => {
                                        HandlerForOpenInfo(ReversedParsedDate, DropPoint.adressOfDropPoint, DropPoint.dropPointId)}) : (() => {
                                    })}>
                                        {
                                            DropPoint.totalMoney > 0 ?
                                                `${(DropPoint.totalMoney).toFixed(2)} Б` : "0 Б"
                                        }
                                        {
                                            DropPoint.totalMoneyFree > 0 &&
                                            `, ${(DropPoint.totalMoneyFree).toFixed(2)} бесп. Б`
                                        }
                                    </div>,
                            },
                            filter: [],
                            visible: [false],
                            style: {backgroundColor: "#f9f9f9"}
                        }
                    )
                    numberOfCardsPerDate += DropPoint.amount;
                    sumSpentPerDay += DropPoint.totalMoney;

                }
                counter = 0;
                spentMoneyEveryDay.push(sumSpentPerDay);
                totalNumberOfCards.push(numberOfCardsPerDate);
                numberOfCardsPerDate = 0;
                sumSpentPerDay = 0;
            }

        }
        setTotalAmount(totalNumberOfCards);
        setTotalSpentMoney(spentMoneyEveryDay);
        setShowModeForEachRow(ShowModeList);
        return list;
    }

    useEffect(() => {
        if (dataForSupplier) {
            let list = ParseDataForOneSupplier(dataForSupplier);
            let mytable: TableWithShowModes = {
                showMode: showModeForEachRow,
                main: {
                    tableId: "SuppliersCardsTable",
                    header: ["Число", "Количество", "Цена за шт.", "Итог"],
                    rows: list
                }
            }
            setFirstTable(mytable);
        }
        if (nextStep === true) store.DataLoadingOFF();


    }, [dataForSupplier, nextStep])

    const GetDataForSupplier = () => {
        store.DataLoadingON();
        CabinetSellerService.getBonusesRemainingDays()
            .then((response) => {
                if (response.data.days > 0)
                    setRemainingDays(response.data.days)
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
            })

        CabinetSellerService.getDailyPayInfo(pageTable, 10)
            .then((response) => {
                setDataForSupplier(sortListOfDates(response.data));
                setTotalTable(response.data.dayInfosCount)
                setNextStep(true);
            })
            .catch(() => {
                store.ErrorON()
            })
            .finally(() => {
                store.DataLoadingOFF()
            })
    }

    // ----------- Конец растасовки данных для поставщика ------------------------------------------ //


    //основной парсинг данных (связь с сервером, Первоначальные запросы)
    useEffect(() => {
        GetDataForSupplier();
    }, [pageTable])


    return (
        <>{
            ((!store.isError && !store.isDataLoading) &&
                <div className={"supplier_scph_main"}>{
                    (FirstTable.main.rows.length > 0) ?
                        (
                            <div className={"supplier_scph_first_page"}>
                                <CustomNavigateHeader title={"Оплата за размещение карточек"} withTrash={false}/>
                                <div className="supplier_scph_first_page_table">
                                    <CustomTable {...FirstTable.main}/>
                                </div>
                                <Pagination
                                    current={pageTable}
                                    onChange={(page) => {
                                        setPageTable(page)
                                    }}
                                    total={totalTable}
                                />
                                <div className="supplier_scph_balance">
                                    <div className="supplier_scph_balance_title">
                                        Остаток:
                                    </div>
                                    <div className="supplier_scph_total">
                                        {`${bonusesLeft}  бонусов  ( ${remainingDays}  дня/-ей )`}
                                    </div>
                                </div>
                                <Transition
                                    in={showInfo}
                                    timeout={500}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    {state => <div className={`supplier_scph_second_page_container ${state}`}
                                    >
                                        <img
                                            src="/Pictures/Закрыть_календарь.svg"
                                            alt="close"
                                            onClick={HandlerForCloseInfo}
                                        />
                                        <div className="catalog-navigate-header">
                                            <div id={"supplier-nh-title-div"}>
                                                <p className={"supplier-nh-title"}>Данные моих товаров</p>
                                            </div>
                                        </div>
                                        <div>{dateInfo}</div>
                                        {(listOfTables.length > 0) ?
                                            (
                                                <div className="supplier_scph_second_page">
                                                    <Searchbar
                                                        changeHandler={changeHandler}
                                                        initialText={"Найти товар"}
                                                    />
                                                    {(!listOfTables.every((table) => (table.rows.every((row) => (row.visible[0] === false))))) ?
                                                        (
                                                            <>
                                                                {SplitTables()}
                                                            </>
                                                        ) :
                                                        (
                                                            <div className="supplier_scph_empty_search">
                                                                Такого товара не обнаружено
                                                            </div>
                                                        )}

                                                </div>
                                            ) :
                                            (
                                                <div className="supplier_scph_empty">
                                                    Записей нет
                                                </div>
                                            )}
                                    </div>}
                                </Transition>
                            </div>
                        ) :
                        (
                            <div className={"supplier_scph_first_page"}>
                                <CustomNavigateHeader title={"Оплата за размещение карточек"} withTrash={false}/>
                                <div className="supplier_scph_empty">
                                    Записей нет
                                </div>
                                <div className="supplier_scph_balance">
                                    <div className="supplier_scph_balance_title">
                                        Остаток:
                                    </div>
                                    <div className="supplier_scph_total">
                                        {`${bonusesLeft} бонусов (${remainingDays} дн)`}
                                    </div>
                                </div>
                            </div>
                        )
                }
                </div>
            )
        }</>
    )
};