import React, {useContext, useRef, useState} from 'react';
import "./TermsPopup.css"
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import Checkbox01 from "../../../../customUI/checkbox01/checkbox01";
import CabinetSellerService from "../../../../../services/CabinetSellerService";
import {Context} from "../../../../../index";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import CustomInput from "../../../../customUI/CustomInput/CustomInput";

interface TermsPopupProps {
    dropAddressId: string
    onClose: () => void
    onUpdate: () => void
    data: {
        visible?: boolean
        description?: string
        pickUpBonuses?: number
        pickUpBonusesStartSum?: number
    }
}

const TermsPopup = ({dropAddressId, onClose, data, onUpdate}:TermsPopupProps) => {
    const {store} = useContext(Context);
    const [visible, setVisible] = useState(data?.visible != undefined? data.visible : true);
    const [description, setDescription] = useState(data?.description??"");

    const [pickUpBonuses, setPickUpBonuses] = useState(data?.pickUpBonuses??0);
    const [pickUpBonusesStartSum, setPickUpBonusesStartSum] = useState(data?.pickUpBonusesStartSum??0);

    const updateTerms = () => {
        store.DataLoadingON()
        let data = {
            Info1: dropAddressId,
            Bool: visible,
            Info2: description,
            Number1: Number(pickUpBonuses) > 0? Number(pickUpBonuses) : 0,
            Number2: Number(pickUpBonusesStartSum) > 0? Number(pickUpBonusesStartSum) : 0
        }

        CabinetSellerService.updateTerms(data)
            .then(() => {
                store.showDialog("Данные были изменены")
                onUpdate()
                onClose()
            })
            .catch((e) => {alert("Что-то пошло не так")})
            .finally(() => store.DataLoadingOFF())
    }

    const ref = useRef(null)

    useOnClickOutside(ref, () => onClose())

    return (
        <div className="termsPopup-container">
            <div className="termsPopup-content" ref={ref}>
                <CustomNavigateHeader title={"Условия доставки"} onClick={() => onClose()} withTrash={false}/>

                Описание

                <textarea
                    className={"termsPopup-description"}
                    onChange={(e) => setDescription(e.target.value) }
                    value={description}
                    placeholder={"Напишите ваши условия доставки и самовывоза для ваших пунктов выдачи"}
                />

                <Checkbox01 checked={visible} changeHandler={(e) => setVisible(e.target.checked)} title={"Показывать описание условий доставки"} />

                <div className={"termsPopup-bonuses"}>
                    <div className={"termsPopup-input"}>
                        Мин. сумма
                        <CustomInput type={"number"} value={pickUpBonusesStartSum}
                                     onChange={(event) => setPickUpBonusesStartSum(event.target.value)} inputSize={"small"}
                                     min={"0"}/>
                        <div className={"termsPopup-input-hint"}>
                            Это значение определяет минимальную сумму заказа для получения бонусов за самовывоз
                        </div>
                    </div>
                    <div className={"termsPopup-input"}>
                        Бонусы за самовывоз
                        <CustomInput type={"number"} value={pickUpBonuses}
                                     onChange={(event) => setPickUpBonuses(event.target.value)} inputSize={"small"}
                                     min={"0"}/>
                        <div className={"termsPopup-input-hint"}>
                            Для деактивации бунусов поставьте 0
                        </div>
                    </div>
                </div>


                <button className="termsPopup-button" onClick={() => updateTerms()}>
                    Сохранить изменения
                </button>
            </div>
        </div>
    );
};

export default TermsPopup;