import React, {ChangeEvent, useCallback, useContext, useEffect, useState} from "react";
import CartEmpty from "../../../customUI/NoProductsPage/NoProductsPage";
import './Cart.css'
import SupplierCard from "./SupplierCard/SupplierCard";
import Boxitem from "../../../customUI/multipleCheckboxes/boxitem";
import {selectAllLevel2, selectAllLevel3, toggleCheck} from "../../../customUI/multipleCheckboxes/functions";
import {CountCurtObj, arraytoCurtObject, deleteCard, sumProducts, findCheckedCards, sumBonuses} from "./functions";
import CartFooter from "./CartFooter";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import BottomCartButton from "../../../customUI/BottomCartButton/BottomCartButton";
import {Context} from "../../../../index";
import './../../../../index.css'
import {ISupplier, ISupplierObject} from "../../../../models/supplier/supplier";
import {getProperty} from "../../../../functions/functions";
import {ICard} from "../../../../models/product/productCard";
import CabinetBuyerService from "../../../../services/CabinetBuyerService";
import {BeatLoader} from "react-spinners";
import {Loader} from "../../../customUI/Loader/Loader";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import {Link, useNavigate} from "react-router-dom";

function Cart() {
    const[loading,setLoading] = useState(false)
    const [checkAll, setCheckAll] = useState(false);
    const [suppliersArray, setSuppliersArray] = useState<ISupplierObject>();
    const {store} = useContext(Context);

    const navigate = useNavigate()

    useEffect(()=> {
        window.scrollTo(0, 0)
    },[])
    useEffect(() => {
            CabinetBuyerService.Basket().then(data => {
                    // for (const key in data.data.suppliersitems){
                    //     if (data.data.suppliersitems[key].suppliercards === null)
                    //         delete data.data.suppliersitems[key];
                    // }
                console.log(data.data)
                    setSuppliersArray(arraytoCurtObject(data.data.suppliersitems))
                })
                .then(()=>{
                    setSuppliersArray((state)=> {
                        const newState = {...state}
                        for (const key in state)
                            newState[key].arrow = true
                        return state;
                    })
                    selectAllLevel3({
                        setstate1:setCheckAll,
                        setstate2:setSuppliersArray,
                        checked:true
                    })
                })
                .then(()=>{setLoading(true)})
    }, [])

    const changeAmount =useCallback(async (orderId: string, newAmount: number, level2: string, productId: string) => {
        CabinetBuyerService.basketUpdateAmount(orderId, newAmount)
            .then((response)=>{
                setSuppliersArray((prevState)=>{
                    const newState = {...prevState};
                    let supplier = getProperty(newState,level2);
                    // @ts-ignore
                    let card = getProperty(supplier.suppliercards, productId);
                    card.orderAmount = response.data;
                    // if (response.data === card.amount)
                    //     alert("Больше нельзя изменить количество!"); если что, то можно заменить на новые кастомные уведы
                    return newState;
                })
            }
            )
    },[])
    const changeSupplier =useCallback(async (id:string, level2:string, supplier:ISupplier, product:ICard)=>{
        product.checked = true;
        supplier.checked = true;
        console.log(supplier)
        setSuppliersArray((prevState)=> {
            const newState = {...prevState}
                if(!getProperty(newState,supplier.suppliername)){
                    let objsupplier:any={
                        ...supplier,
                        arrow:true,
                        suppliercards:{
                            [product.productId]:product
                        }
                    }
                    Object.assign(newState, {[supplier.suppliername]: objsupplier})
                }
                else{
                    Object.assign(newState[supplier.suppliername].suppliercards, {[product.productId]:product})
                }
                // @ts-ignore
            delete newState[level2].suppliercards[id]
                if(Object.keys(newState[level2].suppliercards).length === 0)
                    delete newState[level2];
                return newState;
            })
    },[])
    const arrowhandler = useCallback((level2:string)=>{
        setSuppliersArray((prevState)=> {
            const newState = {...prevState};
            if(prevState)
                newState[level2].arrow = (!prevState[level2].arrow);
            return newState;
        })
    },[])
    const clearAllHandler = () => {
        store.showDialog("вы действительно хотите очистить всю корзину?", true).then(()=>{
            if(store.dialog.dialogResult === true)
            {
                CabinetBuyerService.basketdeleteAllOrders().then(async (response) => {
                    if (response.status === 200) {
                        await setSuppliersArray({});
                    }
                }).then(()=>{store.cartNumber()});
            }
        })
    }
    const handleCheckLevel3 = useCallback((event:ChangeEvent<HTMLInputElement>)=>{
            selectAllLevel3({
                setstate1:setCheckAll,
                setstate2:setSuppliersArray,
                checked:event.target.checked
            })
    },[])

    const deleteCardhandler=useCallback(async(level2name:string, id:string)=>{
        await deleteCard(level2name, id, setSuppliersArray)
    },[])
    
    const favoriteHandler = useCallback((level2:string, level1:string)=>{
        setSuppliersArray((prevState)=> {
            const newState = {...prevState};
            if(prevState)
                { // @ts-ignore
                    newState[level2].suppliercards[level1].onFavorite = (!prevState[level2].suppliercards[level1].onFavorite);
                }
            return newState;
        })
    },[])

    const CheckLevel1 = useCallback((event:ChangeEvent<HTMLInputElement>, level2name:string)=> {
        toggleCheck({
            setstate1:setCheckAll,
            setstate2:setSuppliersArray,
            event:event,
            level2name:level2name
        })
    },[])
    const CheckLevel2 = useCallback((event:ChangeEvent<HTMLInputElement>)=>{
        selectAllLevel2({
            setstate1:setCheckAll,
            setstate2:setSuppliersArray,
            event:event
        })
    },[])

    let Sumprice1 = 0,Sumprice2 = 0;
    let bonuses = 0

    if(suppliersArray){
        Sumprice1 = sumProducts(suppliersArray, false)
        Sumprice2 = sumProducts(suppliersArray, true)
        bonuses = sumBonuses(suppliersArray)
    }

    let Size = CountCurtObj(suppliersArray);

    const Listlevel2:any =()=> {
        var list = [];
        if(suppliersArray) {
            for (var key in suppliersArray) {
                if (suppliersArray[key].suppliername === "Лист ожидания")
                    continue;
                list.push(
                    <SupplierCard
                        key={suppliersArray[key].suppliername}
                        data={suppliersArray[key]}
                        changeSupplier={changeSupplier}
                        CheckLevel1={CheckLevel1}
                        CheckLevel2={CheckLevel2}
                        deleteCardhandler={deleteCardhandler}
                        isWaitingList={false}
                        arrowhandler={arrowhandler}
                        arrow={suppliersArray[key].arrow}
                        favoriteHandler={favoriteHandler}
                        changeAmount={changeAmount}
                    />
                )
            }
        }
        return (list)
    };
    const MemorizedListLevel2 = React.memo(Listlevel2)

    let ListOrderId = findCheckedCards(suppliersArray)

    const postSetInvoice = (list: any) => {
        store.DataLoadingON()
        CabinetBuyerService.postSetInvoice(list)
            .then((resp)=>{
                if (resp.status === 200){
                    navigate("/"+ "OrderFormation")
                }
            })
            .then(()=>{
                store.cartNumber()
            })
            .finally(() => store.DataLoadingOFF())
    }

    return(
        <>
            <div id={"epty-cart-title-div"}>
                <Link to={SpaRoutes.CATALOG} className={"emty-cart-menu-a"}>
                    <img id={"menu-img"} src={"/Pictures/Значок.svg"}/>
                    <p id={"menu-p"}>КАТАЛОГ</p>
                </Link>
                <p className={"cart-title"}>Корзина</p>
            </div>
            {!loading?<Loader load={true} />:
            (Size===0)?(
                    <CartEmpty
                        link1={SpaRoutes.CATALOG_INDEX}
                        link1text={"Найти товар"}
                        link2={SpaRoutes.CATALOG_INDEX}
                        link2text={"Перейти в каталог"}
                        tittle={"В вашей корзине пока ничего нет"}
                        text={"Чтобы выбрать и заказать нужный товар, воспользуйтесь поиском или перейдите в каталог"}
                    />
            ):(
                <div id={"cart-container"}>
                    <div className={"suppliers-cards-div"}>
                        <div className={"suppliers-cards-checkall"}>
                            <div className={`check-all-button ${checkAll&&"check-all-button-black"}`}>
                                <Boxitem
                                    name={checkAll?'Отменить все':'Выбрать все'}
                                    title={checkAll?'Отменить все':'Выбрать все'}
                                    checked={checkAll}
                                    handleClick={handleCheckLevel3}
                                    disabled={true}
                                />
                            </div>

                        </div>
                        <MemorizedListLevel2/>
                        {suppliersArray&&suppliersArray["Лист ожидания"]&&
                            <SupplierCard
                                key={suppliersArray["Лист ожидания"].suppliername}
                                data={suppliersArray["Лист ожидания"]}
                                changeSupplier={changeSupplier}
                                CheckLevel1={CheckLevel1}
                                CheckLevel2={CheckLevel2}
                                deleteCardhandler={deleteCardhandler}
                                isWaitingList={true}
                                arrowhandler={arrowhandler}
                                arrow={suppliersArray["Лист ожидания"].arrow}
                                favoriteHandler={favoriteHandler}
                                changeAmount={changeAmount}
                                className={"supplier-title-div-await"}
                            />
                        }
                    </div>
                    <CartFooter Sum1={Sumprice1} Sum2={Sumprice2} bonuses={bonuses}/>
                    <BottomCartButton
                        link={"OrderFormation"}
                        state={ListOrderId}
                        ServiceFunction={postSetInvoice}
                    />
                </div>
            )}
        </>
    )
}export default Cart;