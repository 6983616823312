import React, {useContext, useEffect, useRef, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import styles from "./Catalog.module.scss"
import Searchbar from "../../customUI/Searchbar/Searchbar";
import FilterForCatalog from "../CatalogOld/FilterForCatalog";
import {Context} from "../../../index";
import CatalogService from "../../../services/CatalogService";
import {
    ICatalogCategory,
    ICatalogFilterData,
    ICatalogSelectedFilter,
    ICatalogTopCategory
} from "../../../models/Catalog/Catalog";
import SwiperCatalog from "./SwiperCatalog/SwiperCatalog";
import DesktopFilterCatalog from "./DesktopFilterCatalog/DesktopFilterCatalog";
import {BeatLoader} from "react-spinners";
import NoProductsPage from "../../customUI/NoProductsPage/NoProductsPage";
import NoProducts from "./NoProducts/NoProducts";
import ProductCard from "../../customUI/ProductCard/ProductCard";
import {SwiperSlide} from "swiper/react";
import {Spin} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import {ISubCategoriesLE} from "../../../models/models";
import MobileFilterCatalog from "./MobileFilterCatalog/MobileFilterCatalog";

const Catalog = () => {
    const {store} = useContext(Context)
    const [params, setParams] = useSearchParams();

    const [twoCategoriesSelected, setTwoCategoriesSelected] = useState(false)
    const [searchTextValue, setSearchTextValue] = useState("")

    const [filtersData, setFiltersData] = useState<ICatalogFilterData>({})
    const [loadingFiltersData, setLoadingFiltersData] = useState(false)
    const [selectedFilters, setSelectedFilters] = useState<ICatalogSelectedFilter>({
        searchText: undefined,
        topCategoryFilter: {topCategoryId: null, filterAttributes: null},
        subCategoryFilter: {subCategoryId: null, filterAttributes: null},
        sellersIds: null,
        brands: null,
    });

    useEffect(() => {
        const searchText = params.get("q") ?? "";
        const topCategoryId = params.get("tc") ?? null;
        const topCategoryFilters = params.get("tcf") ?? null;
        const subCategoryId = params.get("sc") ?? null;
        const subCategoryFilters = params.get("scf") ?? null;
        const sellers = params.get("s") ?? null;
        const brands = params.get("b") ?? null;
        setSearchTextValue(searchText)
        if (topCategoryId && subCategoryId) setTwoCategoriesSelected(true)
        else setTwoCategoriesSelected(false)
        setSelectedFilters({
            searchText,
            topCategoryFilter: {
                topCategoryId,
                filterAttributes: topCategoryFilters ? topCategoryFilters.split(",") : null,
            },
            subCategoryFilter: {
                subCategoryId,
                filterAttributes: subCategoryFilters ? subCategoryFilters.split(",") : null,
            },
            sellersIds: sellers ? sellers.split(",") : null,
            brands: brands ? brands.split(",") : null,
        });
    }, [params]);

    const [catalogData, setCatalogData] = useState<ICatalogCategory[]>([])
    const [productsData, setProductsData] = useState<ICatalogCategory>()
    const [loadingCatalog, setLoadingCatalog] = useState(false)
    const [loadingCategoryId, setLoadingCategoryId] = useState<string>("")

    const [selectedView, setSelectedView] = useState<number>(0)
    const [categoriesData, setCategoriesData] = useState<ICatalogTopCategory[]>()
    const [loadingCategories, setLoadingCategories] = useState<boolean>(false)

    const [isFilterOpen, setFilterOpen] = useState(false)

    const getCatalog = () => {
        setLoadingCatalog(true)
        CatalogService.getCatalog(selectedFilters)
            .then((r) => {
                if (!twoCategoriesSelected)
                    setCatalogData(r.data)
                else
                    setProductsData(r.data)
            })
            .catch((err) => {
                store.showDialog("Что-то пошло не так")
            })
            .finally(() => setLoadingCatalog(false))
    }

    const loadMoreProducts = (categoryId: string) => {
        let filtes = {
            ...selectedFilters,
            CategoryIdForLoadMore: categoryId,
            SkipProductsForLoadMore: !twoCategoriesSelected ? catalogData.find(c => c.categoryId === categoryId)?.products.length : productsData?.products.length
        }
        setLoadingCategoryId(categoryId)
        CatalogService.loadMoreProducts(filtes)
            .then((r) => {
                if (!twoCategoriesSelected) {
                    setCatalogData((prevCatalogData) => {
                        return prevCatalogData.map((category) => {
                            if (category.categoryId === categoryId) {
                                return {
                                    ...category,
                                    products: [...category.products, ...r.data.products],
                                    hasMoreProducts: r.data.hasMoreProducts
                                };
                            }
                            return category;
                        });
                    });
                    setProductsData(undefined)
                } else {
                    setCatalogData([])
                    setProductsData((prevProductsData) => {
                        return {
                            ...prevProductsData,
                            products: [...productsData?.products ?? [], ...r.data.products],
                            hasMoreProducts: r.data.hasMoreProducts
                        };
                    });
                }

            })
            .catch((err) => {
            })
            .finally(() => setLoadingCategoryId(""))
    }

    const firstGetFilters = () => {
        setLoadingFiltersData(true)
        CatalogService.getCatalogFilters(selectedFilters)
            .then((r) => {
                setFiltersData(r.data)
            })
            .catch(() => {
            })
            .finally(() => {
                setLoadingFiltersData(false)
            })
    }

    const GetFilters = (filters: ICatalogSelectedFilter) => {
        setLoadingFiltersData(true)
        CatalogService.getCatalogFilters(filters)
            .then((r) => {
                setFiltersData(r.data)
            })
            .catch(() => {
            })
            .finally(() => {
                setLoadingFiltersData(false)
            })
    }

    const onSelectCategory = (categoryId: string) => {
        if (!selectedFilters.topCategoryFilter?.topCategoryId) {
            setParams({
                ...Object.fromEntries(params),
                tc: categoryId,
            });
        } else {
            setParams({
                ...Object.fromEntries(params),
                sc: categoryId,
            });
        }
    }

    const isInitialMount = useRef(true);

    useEffect(() => {
        if (isInitialMount.current) {
            window.scrollTo(0, 0);
            isInitialMount.current = false;

        } else {
            firstGetFilters();
            getCatalog();
        }
    }, [selectedFilters]);

    const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const searchProducts = (text: string) => {
        setSearchTextValue(text);

        // Очищаем предыдущий таймаут
        if (searchTimeoutRef.current) {
            clearTimeout(searchTimeoutRef.current);
        }

        // Создаём новый таймаут
        searchTimeoutRef.current = setTimeout(() => {
            if (text) {
                setParams({
                    ...Object.fromEntries(params),
                    q: text,
                });
            } else {
                const newParams = new URLSearchParams(params);
                newParams.delete('q');
                setParams(newParams);
            }
        }, 500);
    };

    const applyFilters = (newFilter: ICatalogSelectedFilter) => {
        const newParams = new URLSearchParams(params);

        if (newFilter.searchText) {
            newParams.set('q', newFilter.searchText);
        }

        if (newFilter.topCategoryFilter?.topCategoryId) {
            newParams.set('tc', newFilter.topCategoryFilter.topCategoryId);
            if (newFilter.topCategoryFilter.filterAttributes) {
                newParams.set('tcf', newFilter.topCategoryFilter.filterAttributes.join(','));
            } else {
                newParams.delete('tcf');
            }
        } else {
            newParams.delete('tc');
            newParams.delete('tcf');
        }

        if (newFilter.subCategoryFilter?.subCategoryId) {
            newParams.set('sc', newFilter.subCategoryFilter.subCategoryId);
            if (newFilter.subCategoryFilter.filterAttributes) {
                newParams.set('scf', newFilter.subCategoryFilter.filterAttributes.join(','));
            } else {
                newParams.delete('scf');
            }
        } else {
            newParams.delete('sc');
            newParams.delete('scf');
        }

        if (newFilter.sellersIds) {
            newParams.set('s', newFilter.sellersIds.join(','));
        } else {
            newParams.delete('s');
        }

        if (newFilter.brands) {
            newParams.set('b', newFilter.brands.join(','));
        } else {
            newParams.delete('b');
        }

        setParams(newParams);
    };

    const clearFilters = () => {
        const newParams = new URLSearchParams();

        if (selectedFilters.searchText) {
            newParams.set('q', selectedFilters.searchText);
        }

        setParams(newParams);
    }

    const getCategories = () => {
        setLoadingCategories(true)
        CatalogService.getCategories()
            .then((r) => setCategoriesData(r.data))
            .catch()
            .finally(() => setLoadingCategories(false))
    }

    const selectSubCategory = (topCatId: string, subCatId: string) => {
        const newParams = new URLSearchParams();
        setSelectedView(0)
        setTwoCategoriesSelected(true)
        if (selectedFilters.searchText) {
            newParams.set('q', selectedFilters.searchText);
        }

        newParams.set('tc', topCatId);
        newParams.set('sc', subCatId);

        setParams(newParams);
    }

    useEffect(() => {
        if (selectedView == 1 && !categoriesData) {
            getCategories()
        }
    }, [selectedView]);

    const isMobile = window.innerWidth <= 450;

    return (
        <div className={styles.container}>
            {!isMobile &&
                <DesktopFilterCatalog
                    selectedView={selectedView}
                    onChangeView={setSelectedView}
                    selectedFilters={selectedFilters}
                    filtersData={filtersData}
                    loading={loadingFiltersData}
                    onChangeFilter={(newFilter) => GetFilters(newFilter)}
                    applyFilters={(newFilter) => applyFilters(newFilter)}
                    clearFilters={() => clearFilters()}
                />
            }

            <div className={styles.content}>
                <div className={styles.topBlock} style={{zIndex: isFilterOpen ? "50" : "20"}}>
                    <div className={"catalog_searchBox"}>
                        <Searchbar
                            changeHandler={searchProducts}
                            initialText={"Искать в каталоге"}
                            value={searchTextValue}
                        />
                    </div>

                    {isMobile && (
                        <MobileFilterCatalog
                            selectedView={selectedView}
                            onChangeView={setSelectedView}
                            selectedFilters={selectedFilters}
                            filtersData={filtersData}
                            loading={loadingFiltersData}
                            onChangeFilter={(newFilter) => GetFilters(newFilter)}
                            applyFilters={(newFilter) => applyFilters(newFilter)}
                            clearFilters={() => clearFilters()}
                            onOpen={setFilterOpen}
                        />
                    )}
                </div>

                <div className={!twoCategoriesSelected ? styles.categories : styles.products}>
                    {selectedView === 0 ?
                        loadingCatalog ?
                            <BeatLoader className={styles.productsLoader}/>
                            :
                            !twoCategoriesSelected && catalogData.length > 0 ? catalogData.map((category) => {
                                    return <SwiperCatalog key={category.categoryId}
                                                          category={category}
                                                          loadMoreProducts={loadMoreProducts}
                                                          loadingCategoryId={loadingCategoryId}
                                                          selectedView={selectedView}
                                                          onSelectCategory={onSelectCategory}
                                    />
                                })
                                :
                                twoCategoriesSelected && productsData?.products && productsData?.products.length > 0 ?
                                    <>
                                        {productsData.products.map((p, index) => {
                                            return <ProductCard
                                                isAuth={store.isAuth}
                                                key={index + p.productId}
                                                item={p}
                                            />
                                        })}

                                        {productsData.hasMoreProducts &&
                                            <div className={"swiperPlusContainer"} onClick={() => {
                                                loadMoreProducts(selectedFilters.subCategoryFilter?.subCategoryId ?? "")
                                            }}>
                                                {loadingCategoryId ?
                                                <Spin indicator={<LoadingOutlined style={{fontSize: 50}} spin/>}/>
                                                :
                                                <img src="/Pictures/plus.svg"/>
                                                }
                                            </div>
                                        }
                                    </>
                                    :
                                    <NoProducts
                                        tittle={"Не смогли ничего для вас найти"}
                                        text={"Не нашли по вашему запросу ни одного продукта, попробуйте изменить параметры фильтра или очистить его"}
                                        searchText={searchTextValue}
                                    />
                        :
                        loadingCategories ?
                            <BeatLoader className={styles.productsLoader}/>
                            :
                            categoriesData && categoriesData.length > 0 ?
                                <div className={styles.categoriesContainer}>
                                    {categoriesData.map((tc) => {
                                        return <details className={"cat-header"} key={tc.topCategoryId}>
                                            <summary className={"cat-summary"}>
                                                <div onClick={() => undefined}
                                                     className={"cat-summary-text"}>
                                                    {tc.name}
                                                </div>
                                            </summary>
                                            <div className={"cat-links"}>
                                                {tc.categories && tc.categories.length > 0 ?
                                                    tc.categories.map((c) => {
                                                        return (
                                                            <div
                                                                key={c.categoryId}
                                                                onClick={() => selectSubCategory(tc.topCategoryId, c.categoryId)}
                                                                className={"cat-link-box"}
                                                            >
                                                                {c.name}
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <></>
                                                }
                                            </div>
                                        </details>
                                    })}
                                </div>
                                :
                                <div className={styles.categoriesContainer}>
                                    Нет категорий
                                </div>
                    }
                </div>
            </div>

        </div>
    );
};

export default Catalog;