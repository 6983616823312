import React, {useEffect} from "react";
import './DropdownLocation.css';
import {useState, useRef} from "react";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import {ISupplierAdress, ISupplierAdressWithNullAdress} from "../../../../../models/supplier/supplier";

interface Interface {
    arr?: ISupplierAdress[];
    SetAddress: (orderId: string, address: ISupplierAdress) => void;
    orderId: string;
    addressId: string;
    delivery: boolean;
    setPickAdress: (val: boolean) => void;
    priceOrder: number
}

function DropdownLocation({arr, addressId, delivery, SetAddress, orderId, setPickAdress, priceOrder}: Interface) {

    const [details, setDetails] = useState(false)

    const [change, setChange] = useState("Выберите адрес")
    //const [switchHide,setSwitchHide] = useState(arr && arr[0].streetandNumber);
    const [switchHide, setSwitchHide] = useState("");
    const buttonRef = useRef(null);

    const [selectAddress, setSelectAddress] = useState<ISupplierAdress>();

    const [pickUpBonuses, setPickUpBonuses] = useState<number | undefined>();

    useEffect(() => {
        console.log(addressId);
        if (addressId && addressId !== "" && arr) {
            arr.map((item) => {

                if (item.id === addressId) {
                    setSelectAddress(item)
                    setChange(item.streetandNumber ? item.streetandNumber : "Выберите адрес")
                }
            })
        }
    }, [addressId]);

    useEffect(() => {
        if (change != "Выберите адрес") {
            let item = arr?.find(i => i.streetandNumber === change);
            if (item && item.pickUpBonuses) setPickUpBonuses(item.pickUpBonuses)
        }
    }, [change]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function handlerChange(str: string, item: ISupplierAdress) {
        setChange(str);
        setDetails(!details);
        setPickAdress(false);
        setSelectAddress(item)
        setSwitchHide(str);
        item.id && SetAddress(orderId, item);
    }

    const list = arr && arr.map((item) => {

        if (item && item.streetandNumber && item.streetandNumber != switchHide) {
            return (
                <div className={"item"} onClick={() => handlerChange(item.streetandNumber ?? "", item)}>
                    <div>
                        <div className={"items-location-button"}>{item.streetandNumber}</div>
                        <div className={"items-drop-button"}>
                            {item.destinationOption == 2 ? "Доставка" : "Самовывоз"}
                            {item.terms && item.terms.pickUpBonuses > 0 && priceOrder - item.terms.pickUpBonuses >= item.terms.pickUpBonusesStartSum &&
                                <b style={{color: "#01aba2", marginLeft: "5px"}}>
                                    +{item.terms.pickUpBonuses} Б
                                </b>
                            }
                        </div>
                    </div>
                    <img className={"item-img-of-kind"}
                         src={item.destinationOption == 2 ? "Pictures/GroupDelivery.svg" : "Pictures/VectorStock.svg"}/>
                </div>
            );
        }
    })

    useOnClickOutside(buttonRef, () => {
        setDetails(false)
    })

    return (
        <div className={"dropdownLocation-container"} ref={buttonRef}>
            <div
                style={change !== "Выберите адрес" ? {boxShadow: "0px 0px 5px #01ABA2"} : {}}
                className={(!details) ? ("dropdown-menu-wrapper")
                    : ("dropdown-menu-wrapper open-dd-location")}>


                {selectAddress ?
                    <div className="header-wrapper" onClick={() => setDetails(prev => !prev)}>
                        <div>
                            <div className={"items-location-button"}>{selectAddress.streetandNumber}</div>
                            <div className={"items-drop-button"}>
                                {selectAddress.destinationOption == 2 ? "Доставка" : "Самовывоз"}
                                {selectAddress.terms && selectAddress.terms.pickUpBonuses > 0 && priceOrder - selectAddress.terms.pickUpBonuses >= selectAddress.terms.pickUpBonusesStartSum &&
                                    <b style={{color: "#01aba2", marginLeft: "5px"}}>
                                        +{selectAddress.terms.pickUpBonuses} Б
                                    </b>
                                }
                            </div>
                        </div>
                        <img className={"item-img-of-kind"}
                             src={selectAddress.destinationOption == 2 ? "Pictures/GroupDelivery.svg" : "Pictures/VectorStock.svg"}/>
                    </div>
                    :
                    <div className="header-wrapper" onClick={() => setDetails(prev => !prev)}>
                        <div className={"header-location-button"}>{change}</div>
                        <button className="header-button">
                            <img className={"location-header-show-button"} src={"Pictures/Vector 8black.svg"}/>
                        </button>
                    </div>
                }
            </div>

            {details &&
                <div className="items-wrapper">
                    <div className={"items-list"}>
                        {list}
                    </div>

                    {delivery &&
                        <div className={"item item_changeAdress"} onClick={() => {
                            setDetails(false);
                            setPickAdress(true)
                        }}>
                            <div className={"items-location-button"}>Добавить новый адрес</div>
                            {/*<div className= "item-kind-of-delivery">*/}
                            {/*    <img className={"item-img-of-kind"} src={isDrop?"Pictures/VectorStock.svg":"Pictures/GroupDelivery.svg"}/>*/}
                            {/*</div>*/}
                        </ div>}
                </ div>
            }
        </div>
    )
}

export default DropdownLocation