import {Iburgermenu} from "../../models/models";
import {SpaRoutes} from "../../Routes/spaRoutes";


export const burger_menu:Iburgermenu[]=
    [
        {
            name:"Склады",
            link: SpaRoutes.SUP_MAGAZINE,
            icon:"/Pictures/storage-icon.svg"
        },
        {
            name:"Каталог товаров",
            link: SpaRoutes.CATALOG,
            icon:"/Pictures/catalog.svg"
        },
        {
            icon: "/Pictures/file-text.svg",
            name: "Выполненные заказы",
            link: SpaRoutes.Seller.Orders.Closed,
        },
        {
            name:"Товары (картинки)",
            link: SpaRoutes.SUP_CATALOG,
            icon:"/Pictures/cabinet-file.svg"
        },
        {
            name:"Товары (таблица)",
            link: SpaRoutes.SUPPLIER_PRODUCTS,
            icon:"/Pictures/cabinet-list.svg"
        },
        {
            icon: "/Pictures/help-circle.svg",
            name: "Создать новый товар",
            link: SpaRoutes.SELECT_PRODUCT,
        },
        {
            icon:"/Pictures/clientsIcon.svg",
            name:"Клиенты",
            link: SpaRoutes.Seller.Clients
        },
        {
            icon: "/Pictures/message-square.svg",
            name: "Чат",
            link: SpaRoutes.SUPPLIER_CHAT,
        },
        {
            icon: "/Pictures/book-open.svg",
            name: "Обучение",
            link: SpaRoutes.TRAINING,
        },
        {
            icon: "/Pictures/book-open (1).svg",
            name: "Добавить обучение",
            link: SpaRoutes.ADDTRAINING+"/0",
        },
        {
            icon: "/Pictures/cabinet-circle-pocket.svg",
            name: "История оплаты за карточки",
            link: SpaRoutes.SUP_CARDS_PAYMENT_HISTORY,
        },
        {
            icon: "/Pictures/invoicetablelink.svg",
            name: "Бронирование рекламы",
            link: SpaRoutes.SUPPLIER_RESERVATION_REKLAMA,
        },
        {
            icon: "/Pictures/invoicetablelink.svg",
            name: "Создать рекламу",
            link: SpaRoutes.SUPPLIER_REKLAMA,
        },
        {
            icon: "/Pictures/globe.svg",
            name: "О нас",
            link: SpaRoutes.ABOUT_US,
        }
    ]