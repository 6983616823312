import './OrderFormation.css'
import React, {useContext, useEffect, useState} from "react";
import DropdownOrder from "./DropdownOrder";
import {IOrder, IOrderFormation} from "../../../../models/OrderFormation/IOrderFormation";
import {ICardChanged} from "../../../../models/product/productCard";
import {Context} from "../../../../index";
import {ISupplierAdress} from "../../../../models/supplier/supplier";

interface IOrderFormationItems {
	supplier: IOrder | undefined;
	orderId: string;
	onRemove: () => void;
	index: number;
	SetAddress: (orderId: string, address: ISupplierAdress) => void;
	SetTime: (orderId: string | undefined, from: string, until: string) => void;
	SetComment: (invoiceId: string | undefined, comment: string) => void;
}

function OrderFormationItems(props: IOrderFormationItems) {
	const {store} = useContext(Context)
	let Sum = 0;
	let bonuses = 0;
	props.supplier?.suppliercards.map((item, index) => {
		Sum += Number(item.discount ? item.price2 : item.price1) * Number(item.orderAmount)
		bonuses += item.bonuses * Number(item.orderAmount)
	})
	
	
	return (
		<div id="orderformation-contaiter">
			<div id="supplyer-contaiter"
			     style={props.supplier?.selectedAdress && props.supplier.selectedTime ? {boxShadow: "0px 0px 5px #01ABA2"} : {}}>
				<div className={"NumberOfOrderAndTrash"}>
					<span>Заказ №{props.index + 1}</span>
					<img onClick={props.onRemove} src="/Pictures/КорзинаФормированиеЗаказа.svg"/>
				</div>
				<div style={{display: "flex", justifyContent: "space-between"}}>
					<span>Поставщик:</span>
					<span>Сумма оплаты поставщику:</span>
				</div>
				<div id="supplyer-name-and-number">
					<div style={props.supplier?.supplierlogo ? {display: "flex"} : {marginLeft: "15px"}}>
						{props.supplier?.supplierlogo &&
							<img style={{width: "15px", height: "15px"}} src={store.imgServer + props.supplier?.supplierlogo}/>}
						<span id="supplyer-name">{props.supplier?.suppliername}</span>
					</div>
					<span id="number-of-payment">
                        {Intl.NumberFormat(
	                        "ru",
	                        {style: "currency", currency: "RUB"}
                        ).format(Sum)}

						{bonuses > 0 &&
							<div className="orderFormationItem-bonuses">
								+{bonuses} Б
							</div>
						}
                    </span>
				</div>
				<DropdownOrder
					name={props.supplier?.suppliername}
					orderId={props.orderId}
					cards={props.supplier?.suppliercards}
					delivery={props.supplier?.delivery ? props.supplier.delivery : false}
					adresses={props.supplier?.adressArray}
					addressId={props.supplier?.addressId}
					initialComment={props.supplier ? props.supplier.comment : ""}
					initialFrom={props.supplier ? props.supplier.from : ""}
					initialTo={props.supplier ? props.supplier.to : ""}
					selectedTime={props.supplier ? props.supplier.selectedTime : false}
					SetAddress={props.SetAddress}
					SetTime={props.SetTime}
					SetComment={props.SetComment}
					priceOrder={Sum}
				/>
			</div>
		</div>
	)
}

export default OrderFormationItems