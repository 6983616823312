import CustomInput from "../../../../customUI/CustomInput/CustomInput";
import React, {useContext, useEffect, useRef, useState} from "react";
import {IAttenders} from "../../../../../models/models";
import './PopUpTrainingAttenders.css'
import CustomNavigateHeader from "../../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import useOnClickOutside from "../../../../../hooks/useOnClickOutside";
import {Checkbox} from "antd";
import {Context} from "../../../../../index";
import CabinetSellerService from "../../../../../services/CabinetSellerService";

interface IPopUpTrainingAttendersProps {
    attenders: IAttenders[],
    closePopUp: () => void,
    organizatorId: string,
}


const PopUpTrainingAttenders = ({attenders, closePopUp, organizatorId}: IPopUpTrainingAttendersProps) => {
    const {store} = useContext(Context)

    const [isSendBonuses, setIsSendBonuses] = useState<boolean>(false);
    const [sendBonusesValue, setSendBonusesValue] = useState(0)
    const [customersForSend, setCustomersForSend] = useState<string[]>([]);

    const attendersOnly = attenders.filter(({userId}) => userId !== organizatorId)

    const popupRef = useRef<HTMLDivElement | null>(null);

    useOnClickOutside(popupRef, () => closePopUp())

    const changeCustomersForSend = (id: string) => {
        if (customersForSend.includes(id)) {
            const updatedCustomers = customersForSend.filter((customerId) => customerId !== id);
            setCustomersForSend(updatedCustomers);
        } else {
            const updatedCustomers = [...customersForSend, id];
            setCustomersForSend(updatedCustomers);
        }
    };

    const sendBonuses = () => {
        if(customersForSend.length > 0 && sendBonusesValue > 0){
            if(store.user.bonuses - customersForSend.length * sendBonusesValue >= 0){

                let data = {
                    List1: customersForSend,
                    Number1: sendBonusesValue
                }
                store.DataLoadingON()
                CabinetSellerService.sendBonusesAttendersTraining(data)
                    .then(() => {
                        store.showDialog("Бонусы были отправлены")
                        closePopUp()
                    })
                    .catch(() => store.showDialog("Что-то пошло не так"))
                    .finally(() => store.DataLoadingOFF())
            }
            else{
                store.showDialog("Недостаточно бонусов")
            }
        }
        else if(customersForSend.length == 0)store.showDialog("Вы не выбрали участников")
        else if(sendBonusesValue <= 0)store.showDialog("Нельзя отправить такое кол-во бонусов")
    }

    return (
        <>
            {/*<div className={"popUpTrainingAttenders-close-wrapper"} />*/}
            <div className="popUpTrainingAttenders-wrapper">
                <div className="popUpTrainingAttenders-container" ref={popupRef}>
                    <CustomNavigateHeader title={"Участники мероприятия"} type={"close"} onClick={closePopUp}/>

                    {attendersOnly.length > 0 ? (
                        <>
                            <ul className="popUpTrainingAttenders-list">
                                {attendersOnly.map((attender, index) => {
                                    if(attender && attender.userId){
                                        return <li className="popUpTrainingAttenders-item" key={attender.userId}>
                                            {isSendBonuses &&
                                                <Checkbox
                                                    checked={customersForSend.includes(attender.userId)}
                                                    onChange={() => changeCustomersForSend(attender.userId as string)}
                                                />
                                            }
                                            <span>{index + 1}</span>
                                            <span className="popUpTrainingAttenders-item-name">
                                            {attender.userLastName} {attender.userFirstName}
                                        </span>
                                            <span>{attender.phoneNumber}</span>
                                        </li>
                                    }
                                })}
                            </ul>

                            <div className="popUpTrainingAttenders-sendBonuses-container">
                                {!isSendBonuses ?
                                    <button className="popUpTrainingAttenders-sendBonuses-button"
                                         onClick={() => setIsSendBonuses(true)}>
                                        Отправить бонусы участникам
                                    </button>
                                    :
                                    <div className="popUpTrainingAttenders-sendBonuses">
                                        <CustomInput
                                            type={"number"}
                                            min={"0"}
                                            value={sendBonusesValue}
                                            onChange={(event) => setSendBonusesValue(event.target.value)}
                                            inputSize={"small"}
                                        />
                                        Бонусов
                                        <button className="popUpTrainingAttenders-sendBonuses-button"
                                                onClick={() => sendBonuses()}
                                                disabled={customersForSend.length === 0}
                                        >
                                            Отправить
                                        </button>
                                        <button className="popUpTrainingAttenders-sendBonuses-button"
                                             onClick={() => setIsSendBonuses(false)}>
                                            Отмена
                                        </button>
                                    </div>
                                }


                            </div>
                        </>

                    ) : <div className="popUpTrainingAttenders-empty">Нет участников</div>}

                </div>
            </div>
        </>
    )
}

export default PopUpTrainingAttenders;
