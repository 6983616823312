import {IBurgerSupplierData} from "../../models/models";
import {SpaRoutes} from "../../Routes/spaRoutes";

export const BurgerSupplierData:IBurgerSupplierData[] = [
    {
        name:"Склады",
        link: SpaRoutes.SUP_MAGAZINE,
        image:"/Pictures/storage-icon.svg"
    },
    {
        name:"Каталог товаров",
        link: SpaRoutes.CATALOG,
        image:"/Pictures/catalog.svg"
    },
    {
        name:"Выполненные заказы",
        link: SpaRoutes.Seller.Orders.Closed,
        image:"/Pictures/cabinet-file-success.svg"
    },
    {
        name:"Товары (картинки)",
        link: SpaRoutes.SUP_CATALOG,
        image:"/Pictures/cabinet-file.svg"
    },
    {
        name:"Товары (таблица)",
        link: SpaRoutes.SUPPLIER_PRODUCTS,
        image:"/Pictures/cabinet-list.svg"
    },
    {
        name:"Создать новый товар",
        link: SpaRoutes.SELECT_PRODUCT,
        image:"/Pictures/cabinet-help-circle.svg"
    },
    {
        name:"Клиенты",
        link: SpaRoutes.Seller.Clients,
        image:"/Pictures/clientsIcon.svg"
    },
    {
        name:"Чат",
        link: SpaRoutes.SUPPLIER_CHAT,
        image:"/Pictures/cabinet-message.svg"
    },
    {
        name:"Обучение",
        link: SpaRoutes.TRAINING,
        image:"/Pictures/cabinet-book-open.svg"
    },
    {
        name:"Добавить обучение",
        link: SpaRoutes.ADDTRAINING+"/0",
        image:"/Pictures/cabinet-book-open-add.svg"
    },
    {
        image: "/Pictures/cabinet-circle-pocket.svg",
        name: "История оплаты за карточки",
        link: SpaRoutes.SUP_CARDS_PAYMENT_HISTORY,
    },
    {
        image: "/Pictures/invoicetablelink.svg",
        name: "Бронирование рекламы",
        link: SpaRoutes.SUPPLIER_RESERVATION_REKLAMA
    },
    {
        image: "/Pictures/invoicetablelink.svg",
        name: "Ваша реклама",
        link: SpaRoutes.SUPPLIER_REKLAMA
    },
    {
        name:"О ПроКрасивое",
        link: SpaRoutes.ABOUT_US,
        image:"/Pictures/cabinet-globe.svg"
    }
]