import React, {useContext, useEffect, useState} from "react";
import {useParams, Link, useNavigate} from "react-router-dom";

import {BeatLoader} from "react-spinners";
import {createFilterGroupsForMagazines, getParsedDate} from "../../../../functions/functions";
import {SpaRoutes} from "../../../../Routes/spaRoutes";
import SelectFilterbox, {IFilterBoxGroups} from "../../../Supplier_UI_kit/Filter4Magazines/SelectFilterBox";
import CabinetSellerService from "../../../../services/CabinetSellerService";
import Searchbar from "../../../customUI/Searchbar/Searchbar";
import CustomAntTable from "../../../customUI/CustomAntTable/CustomAntTable";
import {Context} from "../../../../index";
import "./Orders.css";
import CustomNavigateHeader from "../../../customUI/CustomNavigateHeader/CustomNavigateHeader";
import WarehouseFilterBox from "./WarehouseFilterBox/WarehouseFilterBox";

interface Order {
    orderId: string;
    orderDropAddress: {
        city: string;
        street: string;
        number: string;
    };
    startDate: string;
    customerName: string;
    price: number;
    payed: boolean;
    paymentOption: number;
    destination: {
        startDate: string;
    };
    comment: string;
    action: any;
}

const statusMap = {
    open: {title: "Новые заказы", index: 1},
    accepted: {title: "Принятые заказы", index: 2},
    readyToGive: {title: "Готовы к выдаче", index: 3},
    readyToDelivery: {title: "Готовы к отправке", index: 4},
    sending: {title: "В доставке", index: 5},
    received: {title: "Выданные заказы", index: 6},
    closed: {title: "Завершенные заказы", index: 7},
    canceledBuyer: {title: "Ожидают отмены", index: 8},
    canceled: {title: "Отменённые", index: 9},
};

const destinationOptionMap = {
    pickUp: {title: "Самовывоз", index: 0},
    pickUpDeliveryPoint: {title: "Самовывоз", index: 1},
    delivery: {title: "Доставка", index: 2},
}

const Orders: React.FC = () => {
    const {store} = useContext(Context);
    const navigate = useNavigate();
    const [data, setData] = useState<Order[]>([]);
    const [originalData, setOriginalData] = useState<Order[]>([]);
    const [filterGroups, setFilterGroups] = useState<IFilterBoxGroups[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCities, setSelectedCities] = useState<string[]>([]);

    const {status, destinationOption} = useParams<{
        status: keyof typeof statusMap,
        destinationOption?: keyof typeof destinationOptionMap
    }>();
    const statusKey = status && statusMap[status].index;

    const [columns, setColumns] = useState<any>([
        {
            title: "Заказ",
            dataIndex: "orderId",
            render: (text: string, record: Order) => (
                <div className={"orders-table-orderId"}>
                    № {record.orderId}
                    <img
                        alt={"Заказ"}
                        className={"orders-table-actionImg"}
                        src={"/Pictures/invoicetablelink.svg"}
                        onClick={() => navigate(SpaRoutes.SUP_ORDER_PAGE + "/" + record.orderId)}
                    />
                </div>
            ),

        },
        {
            title: "Адрес склада",
            dataIndex: "orderDropAddress",
            render: (addr: Order["orderDropAddress"]) => `${addr.city}, ${addr.street} ${addr.number}`
        },
        {title: "Дата создания", dataIndex: "startDate"},
        {title: "Заказчик", dataIndex: "customerName"},
        {
            title: "Адрес доставки",
            dataIndex: "destination",
            render: (_: any, record: any) => `${record.destination.city}, ${record.destination.street}, ${record.destination.number}`
        },
        {
            title: "Время доставки",
            dataIndex: "destinationTime",
            render: (_: any, record: any) => getDeliveryTime(record.destination)
        }
    ])

    const getData = () => {
        store.DataLoadingON();
        CabinetSellerService.getMagazines().then((response: { data: { magazines: any; }; }) => {
            setFilterGroups(createFilterGroupsForMagazines(response.data.magazines));
        });

        CabinetSellerService.getOrdersByStatus({
            Number1: statusKey,
            Number2: destinationOption ? destinationOptionMap[destinationOption].index : -1
        })
            .then((response) => {
                setOriginalData(response.data);
                setData(response.data);
            })
            .catch((e) => console.log(e))
            .finally(() => store.DataLoadingOFF());
    }

    useEffect(() => {
        getData()

        // для отмененных заказов не показываем стобцы суммы и оплаты
        if (statusKey !== 9) {
            setColumns((prevState: any) => [...prevState,
                {
                    title: "Сумма (₽)",
                    dataIndex: "price",
                    render: (price: boolean, record: any) => (
                        <div>
                            {price} ₽
                            <div className={"orders-table-bonuses"}>
                                ({ record.bonuses + record.destination.pickUpBonuses ?? 0} Б)
                            </div>
                        </div>
                    )
                },
                {
                    title: "Оплата",
                    dataIndex: "payed",
                    render: (payed: boolean, record: { orderId: any; price: any; payed: any; paymentOption: any; }) => (
                        <Link
                            to={SpaRoutes.Seller.OrderPayment + `/${record.orderId}/${record.price}/${record.payed}/${record.paymentOption}`}>
                            <img
                                src={record.payed ? "/Pictures/invoicetablelink-green.svg" : "/Pictures/invoicetablelink.svg"}/>
                        </Link>
                    )
                },
            ]);

        }
        // для конечных заказов не показываем стрелку
        if (statusKey !== 7 && statusKey !== 8 && statusKey !== 9) {
            setColumns((prevState: any) => [...prevState,
                {
                    title: "",
                    dataIndex: "action",
                    render: (_: any, record: any) => {
                        return (
                            <img
                                alt={"Изменить статус"}
                                className={"orders-table-actionImg"}
                                src={"/Pictures/arrow-right.svg"}
                                onClick={() => {
                                    changeStatus(record.orderId)
                                }}
                            />
                        )
                    },
                    width: "50px",
                }]);
        }

    }, [statusKey]);

    const getDeliveryTime = (destination: any) => {
        return destination?.startDate && destination?.endDate ? (
                getParsedDate(destination.startDate, true, false) !== getParsedDate(destination.endDate, true, false) ? (
                    // Если даты совпадают, но время разное - показываем интервал времени

                    `${getParsedDate(destination.startDate, true, false)}, ${getParsedDate(destination.startDate, false, true)} - ${getParsedDate(destination.endDate, false, true)}`

                ) : (
                    // Если даты и время совпадают - указываем "в течение дня"
                    `${getParsedDate(destination.startDate, true, false)}, в течение дня`
                )
            )
            : (
                // Если даты отсутствуют
                "-"
            )
    }

    const changeStatus = (id: string) => {
        store.DataLoadingON();
        CabinetSellerService.orderSetStatus({Info1: id})
            .then(() => getData())
            .catch((e) => {
                console.log(e)
                if (e.response.data.message == "No payment") {
                    store.showDialog("Этот заказ нельзя завершить, так как не подтверждена оплата")
                } else {
                    store.showDialog("Что-то пошло не так")
                }
            })
            .finally(() => store.DataLoadingOFF());
    };

    const applyFilters = () => {
        let filteredData = originalData;

        if (selectedCities.length > 0) {
            filteredData = filteredData.filter(order =>
                selectedCities.includes(order.orderDropAddress.city)
            );
        }

        if (searchTerm) {
            filteredData = filteredData.filter(order =>
                order.orderId.includes(searchTerm)
            );
        }

        setData(filteredData);
    };

    const handleFilterChange = (filters: string[]) => {
        setSelectedCities(filters);
    };

    const handleSearchChange = (search: string) => {
        setSearchTerm(search);
    };

    useEffect(() => {
        applyFilters();
    }, [searchTerm, selectedCities]);

    return (
        <div className="ordersPage">
            <CustomNavigateHeader title={status ? statusMap[status].title : "Заказы"} withTrash={false}/>
            <Searchbar changeHandler={handleSearchChange} initialText="Найти заказ..."/>

            {data.length > 0 &&
                <WarehouseFilterBox orders={originalData} onFilter={(filteredOrders => setData(filteredOrders))}/>
            }
            <CustomAntTable data={data} columns={columns} pagination={{pageSize: 10}}/>
        </div>
    );
};

export default Orders;
